// this file is a wrapper around local storage on web and async storage on native
import I18n from 'i18n-js'

const catchLocalStorageErrors = (fn) => {
  try {
    // if there's no localStorage object, trigger an error similar to when we don't have permissions
    if (!localStorage) throw new Error('Access is denied')

    return fn()
  } catch (e) {
    /**
     * Safari will throw a QuotaExceededError when working in private browsing. We should not reach this point, because
     * we normally don't render the app when localStorage isn't working.
     */
    if (e.name === 'QuotaExceededError') {
      alert(I18n.t('web.object_store.private_browsing_error')) // eslint-disable-line no-alert
      return null
      // when we don't have access (or when there's no localStorage), display an informative modal
    } else if (e.message.match(/Access is denied/)) {
      $('#no-storage-modal').modal()
      // consider this as handled
      return null
    } else {
      // something else unexpected has happened
      throw e
    }
  }
}

export class WebObjectStore {
  static async get(name) {
    return JSON.parse(catchLocalStorageErrors(() => localStorage.getItem(name)))
  }

  static async set(name, value) {
    return catchLocalStorageErrors(() => localStorage.setItem(name, JSON.stringify(value)))
  }

  static async remove(name) {
    return catchLocalStorageErrors(() => localStorage.removeItem(name))
  }
}

let instance = WebObjectStore

export default class ObjectStore {
  static setInstance(newInstance) { instance = newInstance }

  static get(name) { return instance.get(name) }

  static set(name, value) { return instance.set(name, value) }

  static remove(name) { return instance.remove(name) }
}
