import chooseProfilePhoto from './choose_profile_photo'

let alreadyRun = false

export function initChoosePhotoWidget({ photosUrl }) { // eslint-disable-line import/prefer-default-export
  if (alreadyRun) return
  alreadyRun = true

  $('.conference-report').on(
    'click',
    '.choose-photo-widget > .edit-choose-photo-widget-link',
    function click() {
      const $widget = $(this).parent()
      /**
       * We don't want to save profile photos to the server, we just update the URL in the conference
       * report and that data gets saved through the form. Hence, no saveUrl.
       */
      chooseProfilePhoto({
        photosUrl,
        mode: 'pick',
        callback(url) { $widget.find('.url').val(url) },
        photoSelector: $widget.find('img'),
      })
      return false
    },
  )
}
