import { tForFile } from 'shared/i18n'
import moment from 'moment'
import $ from 'jquery'
import 'jquery-validation/dist/jquery.validate.js'
import 'jquery-validation/dist/additional-methods'

const t = tForFile('web.shared.validation')

/**
 * Source: https://emailregex.com/
 * No RegExp is perfect for email, but this one seems pretty legit. It works better than the stock jQuery validate
 * email regexp for our purposes because things like `bar@myschoolcom` (missing the dot) is technically a valid email
 * address but it seems *extremely* unlikely that'll come up, and this is actually a typo.
 */
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape, max-len

export const emailValidation = {
  required: {
    depends() {
      $(this).val($.trim($(this).val()))
      return true
    },
  },
  emailRegex: true,
}

$(() => {
  // Source: https://stackoverflow.com/a/12514519/2672869
  $.validator.addMethod(
    'emailRegex',
    (value, el) => EMAIL_REGEX.test(value),
    t('errors.invalid_email'),
  )

  $.validator.addMethod(
    'validateDateBefore',
    (value, element) => {
      const $startDate = $(element)
      const $endDate = $($startDate.attr('data-end-date-selector'))
      return moment($startDate.val(), 'L').isSameOrBefore(moment($endDate.val(), 'L'))
    },
    t('errors.invalid_before_date'),
  )

  // setting bootstrap 4 defaults, see https://stackoverflow.com/questions/58465113
  $.validator.setDefaults({
    errorElement: 'span',
    errorPlacement: (error, element) => {
      error.addClass('invalid-feedback');

      const $formGroup = element.closest('.form-group')
      $formGroup.find('.invalid-feedback').remove()
      $formGroup.append(error);
    },
    highlight: (element, errorClass, validClass) => {
      $(element).addClass('is-invalid');
    },
    unhighlight: (element, errorClass, validClass) => {
      $(element).removeClass('is-invalid');
    },
  })
})
