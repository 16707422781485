import I18n from 'i18n-js'
import $ from 'jquery'
import { ajaxErrorHandler, isNetworkError, isServerError, isUserError } from 'shared/error_handling'
import error500Image from '../../web/images/500.jpg'
import { tForFile } from 'shared/i18n'
import { initDatePickers, initUppyWebComponent } from 'shared/utils'

const t = tForFile('shared.modal_helpers')

const closeModalIcon = (
  '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
  '  <span aria-hidden="true">&times;</span>' +
  '</button>'
)

/**
 * This should be updated if new handlers are added to the Rails modal_component logic. Otherwise they won't be
 * correctly cleared when the modal happens to reload.
 */
const MODAL_ON_SUCCESS_CLASSES = ['replace', 'dismiss', 'reload'].map(action => `modal--on-success-${action}`)

/**
 * MUTATES. We expect modal partials to come down from the server wrapped in a modal component (i.e. all the stuff
 * that contains the `div.modal-content`. If the wrapping HTML isn't found, throw an error, otherwise
 * replace the contents and update the modal ID.
 */
export const replaceModal = ($modal, newModal) => {
  const $newModal = $(newModal)
  const $modalContentResult = $newModal.find('.modal-content')
  if (!$modalContentResult.length) throw new Error('Did not receive the remote modal wrapped in modal component HTML')

  // only remove our modifier classes. You can't wipe the classes because Bootstrap will not do what you expect. :-/
  $modal.removeClass(...MODAL_ON_SUCCESS_CLASSES)
  // extend the modal container with the id and classes passed in from the newModal
  $modal.attr('id', $newModal.attr('id'))
  $modal.addClass($newModal.prop('class'))
  $modal.find('.modal-content').replaceWith($modalContentResult)

  initDatePickers();
  initUppyWebComponent();
  $modal.find('form').validate()
}

const replaceWithNetworkErrorPage = ($modal, replaceWithOriginal = true) => {
  const originalHtml = $modal.find('.modal-content').contents()

  const secondaryText = t('shared.modal_helpers.network_secondary_text')
  const tertiaryText = t('shared.modal_helpers.network_tertiary_text')

  $modal.find('.modal-content').html(
    `<div class="modal-body">
       ${closeModalIcon}
       <div class="error-page">
         <p class="title"><i class="fas fa-wifi"></i></p>
         <p class="secondary-text">${secondaryText}</p>
         <p>${tertiaryText}</p>
         <button class="btn btn-secondary dismiss-network-warning">${t('actions.back')}</button>
       </div>
     </div>`,
  )

  $modal.find('.dismiss-network-warning').click((e) => {
    if (replaceWithOriginal) {
      $(e.target).parents('.modal-content').html(originalHtml)
    } else {
      $modal.modal('hide')
    }
  })
}

export const replaceWithErrorPage = ($modal) => {
  $modal.find('.modal-content').html(
    `<div class="modal-body">
       ${closeModalIcon}
       <div class="error-page">
         <p class="title">${t('errors.internal_server_error.title')}</p>
         <p class="secondary-text">${t('errors.internal_server_error.secondary_text')}</p>
         <div class="hero-image"><img src="${error500Image}" /></div>
         ${t('errors.internal_server_error.tertiary_text_html')}
       </div>
     </div>`,
  )
}

export const initModalListeners = () => {
  $(document)
    // assumes modifier class is on the outside modal container div
    .on('ajax:success', '.modal--on-success-replace', (e, data) => {
      replaceModal($(e.currentTarget), data)
    })
    .on('ajax:success', '.modal--on-success-dismiss', (e, data) => {
      $(e.currentTarget).modal('hide')
    })
    .on('hidden.bs.modal', '.modal--on-success-dismiss', (e, data) => {
      const target = $(e.currentTarget).find('[data-on-dismiss-reload-selector]').data('on-dismiss-reload-selector')
      if (target) reloadPageWithSpinner(target)
    })
    .on('ajax:error', '.remote-modal', (e, xhr) => {
      if (isUserError(xhr)) {
        ajaxErrorHandler.handle((_e, _xhr) => {
          replaceModal($(e.currentTarget), xhr.responseText)
        })(e, xhr)
      } else if (isNetworkError(xhr)) {
        ajaxErrorHandler.handle((_e, _xhr) => {
          replaceWithNetworkErrorPage($(e.currentTarget))
        })(e, xhr)
      } else if (isServerError(xhr)) {
        ajaxErrorHandler.handle((_e, _xhr) => {
          replaceWithErrorPage($(e.currentTarget))
        })(e, xhr)
      }
    })
}

export function openRemoteModal(e, { url, method, data, onHide, disableBackdropDismiss } = {}) {
  if (e && e.preventDefault) e.preventDefault()

  $('#remote-modal, .remote-modal').remove()
  $('.modal-backdrop').remove()

  $(document.body).append(
    `<div id="remote-modal" class="modal fade remote-modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body"></div>
          </div>
        </div>
      </div>`)

  const $modal = $('#remote-modal')
  if (onHide) $modal.on('hidden.bs.modal', onHide)
  $modal
    .modal({ show: true, ...(disableBackdropDismiss ? { backdrop: 'static' } : {}) })
    .find('.modal-body')
    .loadingLarge()
    .prepend(closeModalIcon)

  return $.ajax(url || $(this).attr('href'), {
    method: method || $(this).attr('data-method') || 'GET',
    data,
    success: (res) => { replaceModal($modal, res) },
    error: ajaxErrorHandler.createAjaxErrorCallback((_e, xhr) => {
      if (isNetworkError(xhr)) {
        replaceWithNetworkErrorPage($modal, false)
      } else {
        replaceWithErrorPage($modal)
      }
    }),
  })
}

export const openConfirmModal = ({ title, detail, actionName, onConfirm }) => {
  $('#confirm-modal').remove()
  $(document.body).append(
    `<div id="confirm-modal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">${title}</h5>
              ${closeModalIcon}
            </div>
            ${detail ? (`<div class="modal-body"><p>${detail}</p></div>`) : ''}
            <div class="modal-footer">
              <button class="btn btn-primary" data-dismiss="modal">${actionName}</button>
              <button class="btn btn-secondary" data-dismiss="modal">${I18n.t('actions.cancel')}</button>
            </div>
          </div>
        </div>
      </div>`)

  const $modal = $('#confirm-modal')
  $modal.find('.btn-primary').click(function foo(e) {
    $(this).attr('disabled', 'disabled')
    onConfirm()
  })
  $modal.modal('show')
}

export const reloadPageWithSpinner = (target) => {
  $(target).loadingLarge()
  window.location.reload()
}
