import React from 'react'
import api from 'shared/api'
import throttle from 'lodash/throttle'
import { tForFile } from 'shared/i18n'
import { Loading } from 'modules/bootstrap_components/components/loading'

import './textile_preview.scss'
import { removeSchoolIdFromUrl } from 'shared/routes'

const t = tForFile('web.shared.components.textile_textarea.textile_preview')

export default class TextilePreview extends React.PureComponent {
  state = {
    loading: true,
    html: null,
    errors: [],
  }

  componentDidMount() {
    this.loadPreview()
  }

  componentDidUpdate(prevProp) {
    // force a recompile...
    if (this.props.textForPreview !== prevProp.textForPreview) {
      this.loadPreview()
    }
  }

  loadPreview = throttle(() => {
    const { textForPreview, renderUrl } = this.props
    api.post(removeSchoolIdFromUrl(renderUrl), { text: textForPreview })
      .then((res) => {
        this.setState({ loading: false, html: res.data.html, errors: res.data.errors || [] })
      })
  }, 1000)

  render() {
    const { loading, html, errors } = this.state

    return (
      <div className="TextilePreview">
        <h4>{t('actions.preview')}</h4>
        {loading && (<Loading />)}
        {errors.length > 0 && (<div className="alert alert-danger">{errors.map(e => (<div>{e}</div>))}</div>)}
        {html && (<div className="card card-body bg-light" dangerouslySetInnerHTML={{ __html: html }} />)}
      </div>
    )
  }
}
