import React from 'react'
import cn from 'classnames'
import './icon.scss'
import { omit } from 'shared/ramda_loader'

const classFromIconStyle = (style) => {
  switch (style) {
    case 'regular':
      return 'far'
    case 'light':
      return 'fal'
    case 'brand':
      return 'fab'
    default:
      return 'fas'
  }
}

export const Icon = (props) => {
  const { className, icon, iconModifiers = [], padLeft, padRight, title, iconStyle, ...rest } = props
  const icons = [icon, ...iconModifiers].map(i => `fa-${i}`).join(' ')
  return (
    <i
      title={title}
      className={cn('Icon', className, classFromIconStyle(iconStyle), icons, {
        'pad-right': padRight,
        'pad-left': padLeft,
      })}
      {...omit(['data-test'], rest)}
    />
  )
}
