import axios from 'axios'
import moment from 'moment'
import { formatDateLL } from './date_utils'
import { assoc, dissocPath, pipe, startsWith, toPairs } from 'shared/ramda_loader'
import { tForFile } from 'shared/i18n'
import { escapeQuotes, isBlank } from 'shared/string_helpers'
import objectStore from 'shared/object_store'
import $ from 'jquery'

const t = tForFile('form_helper')

const indexOfCheckedRadio = $radios => $radios.index($radios.filter(':checked'))

function escapeQuotesToHtml(text) {
  return text.replace(/"/g, '&quot;')
}

function hiddenField(title, required, validationMessage) {
  return `
    <input
      type="hidden"
      name="form[fields_obj_attributes][${escapeQuotesToHtml(title)}]"
      name="form[fields_obj_attributes][${escapeQuotesToHtml(title)}]"
      value=""
      ${required ? 'data-rule-required=\'true\'' : ''}
      ${validationMessage ? `data-msg-required="${validationMessage}"` : ''}
    />
  `
}

export function initSignatureWidget({ title, elementId, required, val, user, readOnly }) {
  $.validator.setDefaults({ ignore: [] }) // don't ignore hidden fields

  const $container = $(elementId)

  const generateHtml = () => {
    if (readOnly) {
      $container.empty().append('<div class="signature"><span></span></div>')
    } else {
      $container.empty().append(`
        <div class='signature'>
          <span></span>
          <a href='#' class='unsign btn btn-secondary'>Click to unsign</a>
        </div>
        <a href='#' class='sign btn btn-secondary'>Click to sign</a>
        ${hiddenField(title, required)}
      `)
    }
  }

  const setSignature = (value) => {
    $('.signature span', $container).html(value)
    $('input', $container).val(value)

    $('.signature', $container).toggle(!!value)
    $('.sign', $container).toggle(!value)
  }

  generateHtml()

  setSignature(val)

  $('a.unsign', $container).click(() => {
    setSignature('')
    return false
  })
  $('a.sign', $container).click(() => {
    setSignature(`${user} on ${formatDateLL(moment())}`)
    return false
  })
}

export function initUploadWidget({ title, elementId, required, link, attachment, fileField }) {
  const $container = $(elementId)
  // stored locally because we may reset it when user chooses to upload different file
  let attachmentLink = attachment

  const generateHtml = () => {
    const uploadSomethingNewButton = `
      <button class="btn btn-secondary upload-different-file">Upload Different File</button>
    `

    $container.empty().append(`
      ${attachmentLink ? (attachmentLink + uploadSomethingNewButton) : fileField}
    `)
  }

  generateHtml()

  $('.upload-different-file', $container).click(() => {
    attachmentLink = null
    generateHtml()
    return false
  })
}

export function initFormItemWidget({ title, elementId, required, status, action, location, formUrl, isComplete }) {
  $.validator.setDefaults({ ignore: [] }) // don't ignore hidden fields

  const $container = $(elementId)

  const generateHtml = () => {
    $container.empty().append(`
      ${status} <button class="btn btn-secondary form-action">${action}</button>
      ${isComplete ? '' : hiddenField(title, required, I18n.t('web.form_helper.form_must_be_submitted'))}
    `)
  }

  const saveForm = (url, data) => {
    const update = pipe(
      assoc('format', 'json'),
      // we're only saving and not submitting, so remove submitted state.
      dissocPath(['form', 'state']),
    )(data)
    return axios.patch(url, update)
  }

  generateHtml()

  $('.form-action', $container).click(() => {
    const $form = $('form#edit_form')
    const data = $form.serializeJSON({ disableColonTypes: true })

    if (!data.form) {
      alert(t('web.modules.template_editor.preview_disclaimer')) // eslint-disable-line no-alert
      return false
    }

    saveForm($form.attr('action'), data)
      .then(() => {
        window.location.assign(location)
      })
    return false
  })
}

export const initHouseholdIncomeWidget = ({ title, elementId, required, bandRanges }) => {
  const $container = $(elementId)
  const $sizeSection = $container.find('.household-size-section')
  const $incomeSection = $container.find('.household-income-section')
  const $sizeRadios = $sizeSection.find('input:radio')

  const showAndUpdateIncomeBrackets = (clearSelection = true) => {
    const peopleIndex = indexOfCheckedRadio($sizeRadios)
    if (peopleIndex === -1) {
      // initially hide the income section when no size has been checked
      $incomeSection.hide(0)
    } else {
      $incomeSection
        .slideDown()
        .find('.form-check-label')
        .map((bracketIndex, e) => $(e).text(bandRanges[peopleIndex][bracketIndex]))
    }

    if (clearSelection) $incomeSection.find('input:radio').prop('checked', false)
  }

  const generateHtml = () => {
    $sizeSection.find('input').change(showAndUpdateIncomeBrackets)
    showAndUpdateIncomeBrackets(false)
  }

  generateHtml()
}

export function initMultipleSelect() {
  $('select[multiple]').chosen({
    placeholder_text_multiple: '- Choose -',
    // allows searching with spaces in search term, as well as searching substrings
    search_contains: true,
    width: '100%',
  })
}

export function initCheckboxGroupRequired() {
  function processCheckboxGroupRequiredState() {
    if ($(this).find(':checkbox').is(':checked')) {
      $(this).find(':checkbox').prop('required', false)
    } else {
      $(this).find(':checkbox').prop('required', true)
    }
  }
  $('.checkbox-group-required').change(processCheckboxGroupRequiredState)
  processCheckboxGroupRequiredState.call($('.checkbox-group-required'))
}

export function initMultipleSelectWithRequiredValidation(elId, originalCssOverrides = {}, options = {}) {
  const $el = $(elId)
  $el.chosen(options)
  setTimeout(() => {
    /**
     * Chosen does not initialize on mobile devices (https://stackoverflow.com/a/22016765/2672869). One way to check
     * if chosen has been initialized is to check for the presence of the chosen-container. The styling applied below
     * will render the entire element invisible when chosen isn't initialized, so do not proceed if that is the case.
     */
    if (!$($el[0].nextElementSibling).hasClass('chosen-container')) return

    $el.addClass('validate-chosen')
    $el.css(originalCssOverrides)
    // manually set the original select to mimic the width of the chosen select
    $el.css('width', $(`${elId}_chosen`).css('width'))
  }, 100)
  return $el
}

export const initCheckboxOrRadioGroupWithOther = ({ choicesSelector, otherTextFieldSelector }) => {
  const $choices = $(choicesSelector)
  const $otherChoice = $choices.last()
  const $otherTextbox = $(otherTextFieldSelector)

  $choices
    .on('change', () => {
      const isOtherSelected = $otherChoice.prop('checked')
      $otherTextbox.prop('required', isOtherSelected)

      // if other is no longer selected, remove red box around textfield
      if (!isOtherSelected) $otherTextbox.valid()
    })

  $otherTextbox
    .on('keydown', () => {
      $otherChoice.prop('checked', true).trigger('change')
    })
    .on('change', () => {
      $otherChoice.val($otherTextbox.val())
    })
}

export const initSelectWithOther = ({ containerId }) => {
  const $textField = $(`${containerId} input`)
  const $selectTag = $(`${containerId} select`)
  const $selectTagLast = $(`${containerId} select option:last`)

  $selectTag.on('change', () => {
    if ($selectTagLast.prop('selected')) {
      $textField.slideDown().prop('required', true)
    } else {
      // call `.valid()` to make the red box validation go away
      $textField.slideUp().prop('required', false).valid()
    }
  })

  $textField.on('change', () => {
    /**
     * We need to reset the "Other" option value back to a non-string when the textfield is blank, because otherwise
     * it'll mark the select as being invalid as well, which we don't want.
     */
    $selectTagLast.attr('value', isBlank($textField.val()) ? '__OTHER__' : $textField.val())
  })
}

const markOption = (nameSelector, value) => {
  $(`[name="${nameSelector}"][value="${escapeQuotes(value)}"]`).prop('checked', true)
}

export const prefillForm = async ($form, localStorageKey, fieldPrefix) => {
  $form.submit((e) => {
    const memento = toPairs($form.serializeForRails()).reduce((acc, [name, value]) => {
      if (value && startsWith('online_application', name)) {
        acc[name] = value
      }
      return acc
    }, {})

    try {
      objectStore.set(localStorageKey, memento)
    } catch (_) {
      // ignore
    }
  })

  try {
    const memento = await objectStore.get(localStorageKey)
    if (!memento) return

    toPairs(memento).forEach(([name, value]) => {
      /**
       * Checkboxes (which will have array values) can't simply be populated by setting their value, so find each
       * checkbox by value and mark them checked. We escape quote marks otherwise it will break the jQuery selector.
       */
      if (Array.isArray(value)) {
        value.forEach((v) => { markOption(`${name}[]`, v) })
      } else if ($(`[name="${name}"]`).attr('type') === 'radio') {
        // ^-- Radios have a similar but different behavior; the individual option must be located and marked
        markOption(name, value)
      } else {
        $(`[name="${name}"]`).val(value)
      }
    })
  } catch (_) {
    // ignore
  }
}
