export function findBeginningOfCurrentLine(textarea) {
  return textarea.value.lastIndexOf('\n', textarea.selectionStart - 1) + 1
}

/* eslint-disable no-param-reassign */
export function updateTextareaState(
  textarea,
  text,
  positionStart,
  positionEnd,
) {
  textarea.value = text
  textarea.selectionStart = positionStart
  textarea.selectionEnd = (typeof positionEnd === 'undefined' ? positionStart : positionEnd)
  return textarea
}

/* eslint-enable no-param-reassign */
