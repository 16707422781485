$ = require('jquery')
require('jquery-cookie/jquery.cookie')

$.cookie.raw = true
$.cookie.json = true
const prefs = { map: null }
$.preferences = function preferences(name, value) {
  if (!prefs.map) {
    prefs.map = $.cookie('preferences')
    if (prefs.map === undefined) {
      prefs.map = {}
    } else {
      prefs.map = JSON.parse(prefs.map)
    }
    // console.debug('loading pref = ', preferences.map)
  }
  if (value) {
    prefs.map[name] = value
    $.cookie('preferences', prefs.map, { path: '/' })
    // console.debug('setting pref = ', preferences.map)
    return value
  } else {
    return prefs.map[name]
  }
}
