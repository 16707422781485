import React from 'react'
import moment from 'moment'
import { tForFile } from 'shared/i18n'
import { Button } from 'modules/bootstrap_components/components/button'

const t = tForFile('web.shared.components.polling_results')

const POLL_DELAY_IN_MS = 2000

const statusMessage = message => (
  t(`enums.statuses.${message}`, { time: moment().format('LTS') })
)

export class PollingResults extends React.Component {
  state = {
    results: '',
    status: '',
    running: false,
    timedOut: false,
  }

  retries = 0
  maxRetries = (this.props.minutes || 20) * 60
  interval

  clockTick = () => {
    this.setState({ status: statusMessage('in_progress') })
  }

  poll = () => {
    if (this.retries >= this.maxRetries) {
      this.setState({
        status: statusMessage('timed_out'),
        timedOut: true,
        running: false,
      })
      clearInterval(this.interval)
      return
    }

    this.retries += 1

    $.ajax(this.props.url, {
      method: 'GET',
      success: (data) => {
        this.setState({ results: data.results || '' })

        if (!data.working) {
          clearInterval(this.interval)
          this.setState({ running: false })

          if (data.success) {
            this.setState({ status: statusMessage('succeeded') })
          } else if (data.failure) {
            this.setState({ status: statusMessage('failed') })
          } else {
            this.setState({ status: statusMessage('finished') })
          }

          if (this.props.onComplete) {
            this.props.onComplete()
          }
        } else {
          this.setState({ status: statusMessage('in_progress') })
          setTimeout(this.poll, POLL_DELAY_IN_MS)
        }
      },
    })
  }

  startPolling = () => {
    this.retries = 0
    this.setState({ timedOut: false, running: true })
    this.interval = setInterval(this.clockTick, 1000)
    setTimeout(this.poll, POLL_DELAY_IN_MS)
  }

  render() {
    const { results, status, timedOut } = this.state

    return (
      <div className="PollingResults">
        <pre className="results" dangerouslySetInnerHTML={{ __html: results }} />
        <div className="status">{status}</div>
        {timedOut && (
          <Button type="link" text={t('actions.check_for_updates')} onClick={this.startPolling} />
        )}
      </div>
    )
  }
}
