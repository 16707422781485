import jstz from 'jstz'

/* eslint-disable import/prefer-default-export */
export function findTimeZone() {
  // jstz uses Intl which sometimes mess things up for Rails, temporarily disable its use: http://bit.ly/2s3JKl4
  const oldIntl = window.Intl
  try {
    window.Intl = undefined
    const tz = jstz.determine().name()
    window.Intl = oldIntl
    return tz
  } catch (e) {
    // sometimes (on android) you can't override intl
    return jstz.determine().name()
  }
}
