import React from 'react'
import { tForFile } from 'shared/i18n'
import './loading.scss'
import cn from 'classnames'

import thingLogoImage from 'images/thin-logo.png'

const t = tForFile('web.shared.components.loading')

export const Loading = ({ align, loadingText, text }) => (
  <div className={cn('rc-loading flex flex-vcenter', { 'flex-center': align === 'center' })} data-test="dt-Loading">
    <img className="spinner" src={thingLogoImage} alt="" />
    {loadingText && t('general.loading')}
    {text && text}
  </div>
)

Loading.defaultProps = {
  align: 'center',
}
