export function maybeRunTour(tour) {
  const queryParams = new URLSearchParams(window.location.search)
  if (queryParams.get('tour') === 'true' || tour.forceShow) {
    if (tour.delay) {
      setTimeout(() => window.hopscotch.startTour(tour, 0), tour.delay)
    } else {
      window.hopscotch.startTour(tour, 0)
    }
  }
}

// Helps with running Hopscotch tours
export function runTourWhenLoaded(elQuery, tour) {
  const tourWithDefaults = {
    ...tour,
    onStart() {
      if (tour.onStart) tour.onStart()
    },

    // records time elapsed in seconds
    onClose() {
      if (tour.onClose) tour.onClose()
    },

    // records time elapsed in seconds
    onEnd() {
      if (tour.onEnd) tour.onEnd()
    },
  }
  const interval = setInterval(() => {
    if ($(elQuery).length) {
      maybeRunTour(tourWithDefaults)
      clearInterval(interval)
    }
  }, 500)
}
