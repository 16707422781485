// Write out isNil here because then it can refine types correctly
import { compose, converge, find, findIndex, nthArg, propEq } from 'shared/ramda_loader'

export const isNil = x => (
  x == null
)

export const isPresent = x => (
  x != null
)

/**
 * Finds an object by its `id`.
 * Same as `findById = (id, array) => find(propEq('id', id), array)` but cooler b/c it's Ramda.
 * Source: https://github.com/ramda/ramda/wiki/Cookbook#get-object-by-id
 */
export const findById = converge(find, [compose(propEq('id'), nthArg(0)), nthArg(1)])

export const findIndexById = (id, xs) => findIndex(propEq('id', id), xs)

/**
 * This is helper to "maybe" find something based on whether the selecting key (namely, an ID) is present. If the ID is
 * present, it returns the type of the finder method, otherwise it returns a maybe type of that return type. Ex:
 *
 *    const maybeId: ?number = 1
 *    const maybeFoo: ?Foo = maybe(findFoo, maybeId)
 *    const definitelyFoo: Foo = maybe(findFoo, 1)
 *
 * This is useful for using selectors that expect an ID, but we don't necessarily have one and just want undefined
 * back.
 *
 * Note: we're using some tricky Flow syntax here. Adapted from here: http://bit.ly/2MPjy6O, made more generic.
 */
export function maybe(cb, id, other) {
  if (!id) {
    return undefined
  } else {
    return cb(id, other)
  }
}
