import moment from 'moment'
import { tenum } from 'shared/i18n'
import { prop, sortBy } from 'shared/ramda_loader'

const HOUR_HEIGHT = 40
const DAY_WIDTH = 100
const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const calculateLeftOffsets = (events) => {
  let previousEvent = null
  const result = []
  sortBy(prop('startTime'), events).forEach((event) => {
    event.left = 0 // eslint-disable-line no-param-reassign
    if (
      previousEvent &&
      (previousEvent.dayOffset === event.dayOffset) &&
      (previousEvent.endHour > event.startHour)
    ) {
      event.left = previousEvent.left + (DAY_WIDTH / 4) // eslint-disable-line no-param-reassign
      if (event.left >= DAY_WIDTH) { event.left = previousEvent.left } // eslint-disable-line no-param-reassign
    }
    result.push(previousEvent = event)
  })
  return result
}

class Calendar {
  constructor($rootElement, days, colorTable) {
    this.$rootElement = $rootElement
    this.days = days
    this.colorTable = colorTable
    this.startOfDay = 24
    this.endOfDay = 0
  }

  $rootElement
  days
  colorTable
  startOfDay
  endOfDay

  calculateDaysAndHours(events) {
    let time
    const firstDay = moment(this.days[0])
    return Array.from(events).map((event) => {
      time = moment(event.time, TIME_FORMAT)
      event.dayOffset = time.diff(firstDay, 'days') // eslint-disable-line no-param-reassign
      event.startHour = time.hour() + (time.minutes() / 60.0) // eslint-disable-line no-param-reassign
      event.endHour = (() => { // eslint-disable-line no-param-reassign
        if (event.endTime) {
          const et = moment(event.endTime, TIME_FORMAT)
          return et.hour() + (et.minutes() / 60.0)
        } else {
          return event.startHour + 0.4
        }
      })()
      this.startOfDay = Math.min(this.startOfDay, Math.floor(event.startHour))
      this.endOfDay = Math.max(this.endOfDay, Math.ceil(event.endHour))

      return event
    })
  }

  drawSkeleton(events) {
    let hoursHtml = ''
    const $header = $('<div class="hour-header"></div>')
    let hour = this.startOfDay
    while (hour <= this.endOfDay) {
      hoursHtml += '<div class="hour"><div class="half-hour"></div></div>'
      $header.append(`<label>${hour}:00</label>`)
      hour += 1
    }

    const $days = $('<div class="days"></div>')
    this.days.forEach((day) => {
      const label = moment(day).format('dd M/D')
      const width = 100 / this.days.length
      const $day = $(`${`<div class='day' style='width: ${width}%'>` +
        `<div class='day-header'>${label}</div>` +
        '<div class="hours">'}${hoursHtml}</div>` +
        '</div>')
      $days.append($day)
    })

    this.$rootElement.append($header).append($days)
  }

  draw(events) {
    let $day
    let $event
    this.calculateDaysAndHours(events)
    calculateLeftOffsets(events)

    this.drawSkeleton()

    return events.map((event) => {
      // noinspection CssInvalidPseudoSelector
      $day = $(`.day:nth(${event.dayOffset}) .hours`)
      $event = $('<div class="event"/>')
      $event.addClass(`${event.event_type}-event`)
      $event.html(tenum('event_types', event.event_type))
      $event.attr('title', tenum('event_types', event.event_type))

      $event.css({
        top: (event.startHour - this.startOfDay) * HOUR_HEIGHT,
        left: event.left + 1,
        right: 1,
        height: (event.endHour - event.startHour) * HOUR_HEIGHT,
      })

      return $day.append($event)
    })
  }
}

window.Calendar = Calendar
