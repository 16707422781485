import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['signature', 'image'];
  static values = { signature: String, width: Number, height: Number };

  connect() {
    this.imageTarget.style.backgroundColor = 'white';

    if (this.signatureValue.includes('data:image/png')) {
      this.imageTarget.src = JSON.parse(this.signatureValue);
    } else {
      this.canvas = document.createElement('canvas');
      this.canvas.height = 220;
      this.canvas.width = 720;
      const ctx = this.canvas.getContext('2d', { lineWidth: 1, strokeStyle: 'black' });
      const coordinates = JSON.parse(this.signatureValue)

      ctx.beginPath();
      coordinates.forEach((coord) => {
        ctx.moveTo(coord.lx, coord.ly);
        ctx.lineTo(coord.mx, coord.my);
      });
      ctx.stroke();

      this.imageTarget.src = this.canvas.toDataURL('image/png');
    }
  }

  disconnect() {
    this.canvas.remove();
  }
}
