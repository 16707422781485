export const HandledRejection = rejection => new HandledRejectionError(rejection)
export const isHandled = rejection => rejection._handled

class HandledRejectionError extends Error {
  constructor(rejection, ...params) {
    super(...params);
    this.name = 'HandledRejectionError';
    this.rejection = rejection;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HandledRejectionError);
    }
  }

  /* eslint-disable class-methods-use-this */
  get _handled() {
    return true;
  }
}

/**
 * Represents a rejection that has been handled in some form (perhaps an error message has been reported). Useful for
 * propagating a rejection down the chain without requiring every consumer of a promise to have a `.catch` when it's
 * not necessary.
 */
export const HandledPromiseReject = rejection => (
  // When rejecting an already handled rejection, just propagate it, otherwise wrap in a rejection object
  rejection && isHandled(rejection)
    ? Promise.reject(rejection)
    : Promise.reject(HandledRejection(rejection))
)
