/* eslint-disable no-console */
import { last } from 'shared/ramda_loader'
import util from 'util'

/**
 * spit out to console and return value passed in
 * if 2 args passed in, print both, return 2nd
 */
export const spit = (...args) => {
  console.log(...args.map(arg => util.inspect(arg, { showHidden: false, depth: null })))
  return last(args)
}

let counts = {}

export function count(thing) {
  const currentCount = counts[thing] || 0
  counts[thing] = currentCount + 1
}

export function dumpCounts() {
  console.log('Counts: ', counts)
}

export function clearCounts() {
  counts = {}
}

export function time(name, fn) {
  const start = new Date()
  const retval = fn()
  console.log(name, `${new Date() - start} ms`)
  return retval
}

/**
 * Middleware to print out counts on each Redux action
 */
export function printCounts(store) {
  return next => (action) => {
    next(action)
    dumpCounts()
    clearCounts()
  }
}

export const sleep = async timeInMs => new Promise(res => setTimeout(res, timeInMs))
