import I18n from 'i18n-js'

export function tForFile(path) {
  return (key, ...args) => {
    if (key[0] === '.') {
      return I18n.t(`${path}${key}`, ...args)
    } else {
      return I18n.t(key, ...args)
    }
  }
}

export function tenum(type, key) {
  return I18n.t(`enums.${type}.${key}`)
}
