import { mergeRight, toPairs } from 'shared/ramda_loader'

export function parseUrl(url) {
  const [nonAnchor, anchor] = url.split('#')
  const [base, paramsString] = nonAnchor.split('?')
  const params = {}
  if (paramsString) {
    paramsString.split('&').forEach((part) => {
      const param = part.split('=')
      params[param[0]] = param[1]
    })
  }
  return { base, params, anchor }
}

function assembleUrl({ base, params, anchor }) {
  let url = base
  if (params) {
    url = [url, toPairs(params).map(i => `${i[0]}=${i[1]}`).join('&')].join('?')
  }
  if (anchor) {
    url = [url, anchor].join('#')
  }
  return url
}

export const modUrl = (url, options) => {
  let { base, params, anchor } = parseUrl(url) // eslint-disable-line prefer-const

  if (options.params) {
    params = mergeRight(params, options.params)
  }

  if (options.append) {
    base += options.append
  }

  return assembleUrl({ base, params, anchor })
}
