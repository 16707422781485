import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['token']
  static values = { knownTokens: Array }

  tokenUpdate(event) {
    this.tokenTarget.value = event.detail.token
    this.saveToken()
  }

  saveToken() {
    if (this.tokenTarget.value === '') { return }
    if (this.knownTokensValue.includes(this.tokenTarget.value)) { return }
    if (document.documentElement.ariaBusy) { return }

    this.element.requestSubmit()
  }
}
