/* eslint-disable react/prop-types */
import React from 'react'
import cn from 'classnames'
import { Button } from './button'
import { optProp } from 'shared/object_helpers'

export const DropdownItem = ({ label, onClick }) => (
  <button className="dropdown-item" onClick={onClick}>{label}</button>
)

const MAX_ITEMS_LENGTH_FOR_DYNAMIC_DISPLAY = 10

export class ButtonDropdown extends React.Component {
  static defaultProps = {
    links: [],
    items: [],
  }

  shouldDisplayStatic = () => (
    this.props.static || this.props.items.length > MAX_ITEMS_LENGTH_FOR_DYNAMIC_DISPLAY
  )

  defaultButton = () => (
    <Button
      dropdown
      dropdownNoCaret={this.props.noCaret}
      icon={this.props.icon}
      text={this.props.text}
      flat={this.props.flat}
      className={this.props.buttonClassName}
      {...optProp(this.props.iconStyle, { iconStyle: this.props.iconStyle })}
      {...optProp(this.shouldDisplayStatic(), { 'data-display': 'static' })}
    />
  )

  render() {
    const { button = this.defaultButton(), children, links, items, menuRight } = this.props
    return (
      <div className="BtnDropdown dropdown">
        {button}
        <div className={cn('dropdown-menu', { 'dropdown-menu-right': menuRight })}>
          {children}
          {React.Children.map(items, item => (React.cloneElement(item, { className: 'dropdown-item' })))}
          {links.map((link, index) => {
            if (link.divider) {
              // eslint-disable-next-line react/no-array-index-key
              return (<div key={index} role="separator" className="dropdown-divider" />)
            }
            const { label, active, ...rest } = link
            // eslint-disable-next-line react/no-array-index-key
            return (<a key={index} {...rest} className={cn('dropdown-item', { active })}>{label}</a>)
          })}
        </div>
      </div>
    )
  }
}
