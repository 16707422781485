import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['sectionMenu', 'menuItem', 'dropdownButton', 'dropdownLink']
  static classes = ['menuItemHidden', 'dropdownButtonHidden', 'dropdownLinkHidden']

  connect() {
    this.fit()
  }

  fit() {
    const menuItemsToShow = this.numberOfMenuItemsThatFit()

    if (menuItemsToShow < this.menuItemTargets.length) {
      this.showDropdownMenu()
    } else {
      this.hideDropdownMenu()
    }

    this.menuItemTargets.forEach((_menuItem, i) => {
      if (i + 1 <= menuItemsToShow) {
        this.showInline(i)
      } else {
        this.showInDropdown(i)
      }
    })
  }

  numberOfMenuItemsThatFit() {
    const sectionMenuWidth = this.sectionMenuTarget.clientWidth
    let previousItemsWidth = 0

    // Make sure all menu items and the dropdown menu are showing so we can tell
    // how much space they need.
    this.menuItemTargets.forEach((_menuItem, i) => { this.showInline(i) })
    this.showDropdownMenu()

    for (let i = 0; i < this.menuItemTargets.length; i++) {
      let availableSpace = sectionMenuWidth - previousItemsWidth
      if (i < (this.menuItemTargets.length - 1)) {
        availableSpace -= this.dropdownButtonTarget.offsetWidth
      }

      if (availableSpace < this.menuItemTargets[i].offsetWidth) {
        return i;
      }

      previousItemsWidth += this.menuItemTargets[i].offsetWidth
    }

    return this.menuItemTargets.length
  }

  showDropdownMenu() {
    this.dropdownButtonTarget.classList.remove(this.dropdownButtonHiddenClass)
  }

  hideDropdownMenu() {
    this.dropdownButtonTarget.classList.add(this.dropdownButtonHiddenClass)
  }

  showInline(itemIndex) {
    this.menuItemTargets[itemIndex].classList.remove(this.menuItemHiddenClass)
    this.dropdownLinkTargets[itemIndex].classList.add(this.dropdownLinkHiddenClass)
  }

  showInDropdown(itemIndex) {
    this.menuItemTargets[itemIndex].classList.add(this.menuItemHiddenClass)
    this.dropdownLinkTargets[itemIndex].classList.remove(this.dropdownLinkHiddenClass)
  }
}
