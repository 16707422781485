export default function openRemoteSheet(e, data = {}) {
  e.preventDefault()
  $('#remote-sheet').remove()

  $(document.body).append('<div class=\'sheet slide\' id=\'remote-sheet\'><div class=\'sheet-content\'></div></div>')
  const $sheet = $('#remote-sheet')
  $sheet.sheet({ sheetParent: $(this).attr('data-sheet-parent') })
  $sheet.find('.sheet-content').loading()
  return $.ajax($(this).attr('href'), {
    method: $(this).attr('data-sheet-method') || 'GET',
    data,
    success: (res) => {
      $sheet.find('.sheet-content').html(res)
    },
  })
}
