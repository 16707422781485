import React from 'react'
import cn from 'classnames'
import $ from 'jquery'
import I18n from 'i18n-js'
import { Button } from './button'
import { isPromise } from 'shared/ramda_helpers'
import './modal.scss'
import { HandledPromiseReject } from 'shared/handled_rejection'
import { SpinnerOverlay } from 'shared/components/spinner_overlay'
import { noop } from 'shared/noop'

export class Modal extends React.PureComponent {
  static contains = $el => $el.parents('.Modal').length > 0
  static offset = $el => $el.offset()

  static defaultProps = {
    hidden: false,
    onClose: noop,
    restrictHeight: true,
  }

  state = {
    saving: false,
  }

  componentDidMount() {
    const { hidden, restrictHeight, onClose } = this.props
    if (!hidden) $(this.modal).modal('show')
    $(this.modal).on('hidden.bs.modal', onClose)
    if (restrictHeight) {
      /**
       * Restrict modal to 0.7 height of the window, and force scrolling instead.
       * NB: this does mess with a lot of positioning things, esp. sticky elements.
       */
      $(this.modal).find('.modal-body').css('overflow-y', 'auto')
      $(this.modal).find('.modal-body').css('max-height', $(window).height() * 0.7)
    }
  }

  componentWillUnmount() {
    $(this.modal).off()
  }

  modal

  save = (cb) => {
    this.setState({ saving: true })
    if (this.props.saveable) {
      const res = this.props.onSave()
      if (isPromise(res)) {
        res
          .then(() => this.close(cb))
          .catch((err) => {
            this.setState({ saving: false })
            return HandledPromiseReject(err)
          })
      } else {
        this.close(cb)
      }
    } else {
      $(this.modal).modal('hide')
    }
  }

  close = (cb) => {
    this.setState({ saving: false })
    $(this.modal).modal('hide')
    if (typeof cb === 'function') $(this.modal).on('hidden.bs.modal', cb)
  }

  show = () => {
    $(this.modal).modal('show')
  }

  renderFooter = () => {
    const { loading } = this.props
    if (this.props.footer) {
      return this.props.footer
    } else if (this.props.saveable) {
      return [
        <Button
          key="save"
          type="primary"
          text={I18n.t('actions.save')}
          onClick={() => { this.save() }}
          data-test="dt-Modal-save"
          loading={this.state.saving}
          disabled={!!this.props.disabled || loading}
        />,
        <Button
          key="cancel"
          text={this.props.saveable ? I18n.t('actions.cancel') : I18n.t('actions.close')}
          data-dismiss="modal"
          data-test="dt-Modal-cancel"
          disabled={this.state.saving || loading}
        />,
      ]
    } else {
      return (
        <Button text={I18n.t('actions.close')} data-dismiss="modal" data-test="dt-Modal-close" />
      )
    }
  }

  render() {
    const { large, loading, title, children, className, preventDismiss, footerSplit, scrollable } = this.props
    const { saving } = this.state

    return (
      <div
        className={cn('Modal', 'modal', 'fade', className)}
        ref={(e) => { this.modal = e }}
        tabIndex="-1"
        {...preventDismiss ? { 'data-backdrop': 'static' } : {}}
      >
        <div className={cn('modal-dialog', { 'modal-lg': large, 'modal-dialog-scrollable': scrollable })}>
          <div className="modal-content">
            <SpinnerOverlay showing={loading || saving} state="loading" />
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              {!preventDismiss && (
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
            </div>
            <div className="modal-body">
              {children}
            </div>
            <div className={cn('modal-footer', { 'modal-footer__split': footerSplit })} data-test="dt-Modal-footer">
              {this.renderFooter()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
