// retry until interval is cancelled or we reach maxTries
// eslint-disable-next-line func-names
export default function (fn, maxTries = 30, intervalDuration = 1000) {
  let numRetries = 0
  const interval = setInterval(() => {
    if (numRetries > maxTries) {
      clearInterval(interval)
      return
    }

    fn(interval)

    numRetries += 1
  }, intervalDuration)
}
