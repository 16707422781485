import { isEmpty, last, max, min, path, pipe, replace, toUpper } from 'shared/ramda_loader'
import { isNil } from 'shared/ramda_helpers_flow'
import { isNotNil } from 'shared/ramda_helpers'
import { tForFile } from 'shared/i18n'

const t = tForFile('web.shared.string_helpers')

// Deprecate in favor of writing `!str`
export const isBlank = x => (
  isNil(x) || !x || isEmpty(x) || (typeof x === 'string' && x.replace(/\s/g, '') === '')
)

/**
 * Capitalizes the first letter of the string.
 */
export function capitalize(text) {
  return text.length > 0 ? text[0].toUpperCase() + text.slice(1) : ''
}

export function titleize(str) {
  // return str.replace(/_/g, ' ').replace(/\b([a-z])/g, l => l.toUpperCase())
  return capitalize(str.replace(/_/g, ' ').replace(/ (\w)/g, toUpper))
}

// Deprecate in favor of writing `str`
export function isPresent(str) {
  return !isBlank(str)
}

/**
 * This captures all symbols that are not "letters" (in the broad sense). It ignores @ and # so that regexes to find
 * the word boundary can include @ and #.
 */
const symbolRanges = '\\s!"$-\'*-/;-?[-`{-~'
export const NON_WORD_REGEX = new RegExp(`[${symbolRanges}]+`, 'g')
export const TAG_REGEX = new RegExp(`[@#]([^${symbolRanges}]+)`, 'g')
export const NORMALIZED_TEXT_TAG_REGEX = /\[(child|lesson|user)_(\d+)]/
export const NORMALIZED_LESSON_TAG_REGEX = /\[(lesson)_(\d+)]/
export const NORMALIZED_CHILD_TAG_REGEX = /\[(child)_(\d+)]/

/**
 * Takes a name, like "Jim Willens", and wikifies it, "JimWillens"
 * @param str
 */
export function wikify(str) {
  return str.replace(NON_WORD_REGEX, ' ')
    .trim()
    .split(' ')
    .map(capitalize)
    .join('')
}

/**
 * Source: http://bit.ly/2pEtPds
 */
function regexIndexOf(str, regex, startPosition) {
  const indexOf = str.substring(startPosition || 0).search(regex)
  return (indexOf >= 0) ? (indexOf + (startPosition || 0)) : indexOf
}

/**
 * Source: http://bit.ly/2pEtPds
 * @param str
 * @param regex
 * @param startPosition Does not support negative numbers!
 * @return {number}
 */
function regexLastIndexOf(str, regex, startPosition = str.length) {
  const regexFlags = `g${regex.ignoreCase ? 'i' : ''}${regex.multiline ? 'm' : ''}`

  const globalRegex = (regex.global) ? regex : new RegExp(regex.source, regexFlags)
  const stringToWorkWith = str.substring(0, startPosition + 1)
  let lastIndexOf = -1
  let nextStop = 0
  let result = globalRegex.exec(stringToWorkWith)
  while (result !== null) {
    lastIndexOf = result.index
    globalRegex.lastIndex = ++nextStop
    result = globalRegex.exec(stringToWorkWith)
  }
  return lastIndexOf
}

export function wordAt(text, pos) {
  const start = 1 + regexLastIndexOf(text, NON_WORD_REGEX, pos - 1)
  let end = regexIndexOf(text, NON_WORD_REGEX, pos)
  if (end === -1) end = text.length
  return { word: text.substring(start, end), start, end }
}

export function insertAt(text, toInsert, position) {
  const adjustedPosition = pipe(
    min(text.length),
    max(0),
  )(position < 0 ? text.length + position : position)
  return {
    text: text.slice(0, adjustedPosition) + toInsert + text.slice(adjustedPosition),
    position: adjustedPosition + toInsert.length,
  }
}

export function replaceAt(text, toInsert, start, end, offset = 0) {
  if (start > end || start < 0 || end < 0 || start > text.length || end > text.length) {
    throw new Error(`#replaceAt function does not support parameters start ${start}, end ${end} for text '${text}'.`)
  }
  return {
    text: text.slice(0, start) + toInsert + text.slice(end),
    position: start + toInsert.length + offset,
  }
}

export function findEndOfLine(text, start) {
  const lastIndex = text.indexOf('\n', start)
  return lastIndex !== -1 ? lastIndex : text.length
}

/**
 * Adds a period at the end if it's not present.
 */
export const periodify = text => (last(text) === '.' ? text : `${text}.`)

export const basename = (pathStr) => {
  const match = pathStr.match(/([^/]*)$/)
  if (!match) throw new Error('something went horribly wrong with basename :-(')
  return match[0]
}

// the name of the containing directory for a path
export const dirname = (pathStr) => {
  if (pathStr === '/') return '/'
  // regex: grab everything from beginning up to the last chunk that doesn't contain a /
  const match = pathStr.match(/^(.*)\/[^/]*$/)
  if (!match) return '.'
  return match[1]
}

// source: https://vladimir-ivanov.net/camelcase-to-snake_case-and-vice-versa-with-javascript/
export const camelToSnake = string => string.replace(/[\w]([A-Z])/g, m => `${m[0]}_${m[1]}`).toLowerCase()

export const fallbackToSentence = (words) => {
  if (isEmpty(words)) return ''
  if (words.length === 1) return last(words)
  if (words.length === 2) return `${words[0]} ${t('.and')} ${words[1]}`
  return `${words.slice(0, -1).join(', ')}, ${t('.and')} ${last(words)}`
}

// Intl.ListFormat isn't fully supported (https://caniuse.com/?search=ListFormat), so check if it exists first,
// otherwise fall back
const conjunctionFormatter = words => (isNotNil(path(['Intl', 'ListFormat'], window))
  ? new Intl.ListFormat(window.I18n.locale, { style: 'long', type: 'conjunction' }).format(words)
  : fallbackToSentence(words))

export const toSentence = words => conjunctionFormatter(words)

export const removeHtmlTags = html => replace(/<[^>]+>/g, '', html)

export const escapeQuotes = text => text.replace(/"/g, '\\"')

// https://stackoverflow.com/a/37909363/2672869
export const nbspForReact = '\u00A0'
