import { path } from 'shared/ramda_loader'

/**
 * Returns headers that include the CSRF token for the webapp to communicate with the server. Uses global env variable,
 * which is set in `application.html.erb`. More on how Rails protects against CSRF attacks here:
 * https://medium.com/rubyinside/a-deep-dive-into-csrf-protection-in-rails-19fa0a42c0ef and
 * https://guides.rubyonrails.org/security.html. This token is necessary because of that protection.
 *
 * Unfortunately, it's exceptionally difficult to mock this value in Cypress, so we'll use a safe accessor even though
 * it's reasonable to assume it'd be there in the event this is called. (I.E. we should only be calling this function
 * from a web environment, where we'd have the global env set.)
 */
export const csrfTokenHeaders = () => ({ common: { 'X-CSRF-Token': path(['tc', 'env', 'csrfToken'], window) } })
