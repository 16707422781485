import React from 'react'
import { Modal } from 'modules/bootstrap_components/components/modal'
import { tenum, tForFile } from 'shared/i18n'
import { schoolUrlHelper } from 'shared/school_url_helper'

import './textile_help_modal.scss'

const t = tForFile('web.shared.components.textile_textarea.textile_help_modal')

export default class TextileHelpModal extends React.PureComponent {
  static compare = (pre, html) => (
    <div className="row" key={pre}>
      <div className="col-md-6">
        <pre>
          {pre}
        </pre>
      </div>
      <div className="col-md-6">
        {html}
      </div>
    </div>
  )

  renderComparisons = () => {
    const { extended } = this.props
    let linksPre
    let linksHtml

    // this is not great, it's a hodgepodge of terms borrowed from other places, primarily the TextileTextarea
    const classroom = tenum('classroom_types', 'primary')
    const otherPages = t('.other_pages')
    const bold = t('web.shared.components.textile_textarea.textile_textarea.bold')
    const italic = t('web.shared.components.textile_textarea.textile_textarea.italic')
    const link = t('web.shared.components.textile_textarea.textile_textarea.link')
    const image = t('web.shared.components.textile_textarea.textile_textarea.image')
    const table = t('web.shared.components.textile_textarea.textile_textarea.table')
    const youtube = t('web.shared.components.textile_textarea.textile_textarea.youtube')
    const quote = t('web.shared.components.textile_textarea.formatter.quote_demo')
    const heading1 = t('web.shared.components.textile_textarea.textile_textarea.heading_1')
    const heading2 = t('web.shared.components.textile_textarea.textile_textarea.heading_2')
    const heading3 = t('web.shared.components.textile_textarea.textile_textarea.heading_3')
    const name = t('activerecord.attributes.child.name')
    const age = t('activerecord.attributes.child.age')

    if (extended) {
      linksPre = `"Google":http://google.com\n\n"${classroom}":/classrooms/1\n\n${otherPages}: [${link}]`
      linksHtml = [
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <p key="external"><a href="http://google.com">Google</a></p>,
        <p key="relative"><a href="/classrooms/1">{classroom}</a></p>,
        <p key="other">{otherPages}: <a href={schoolUrlHelper('pages/links')}>[{link}]</a></p>,
      ]
    } else {
      linksPre = `"Google":http://google.com\n\n"${classroom}":/classrooms/1`
      linksHtml = [
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <p key="external"><a href="http://google.com">Google</a></p>,
        <p key="relative"><a href="/classrooms/1">{classroom}</a></p>,
      ]
    }

    return [
      <div key="comparison-key" className="row">
        <div className="col-md-6"><h1>{t('.input_prompt')}</h1></div>
        <div className="col-md-6"><h1>{t('.output_prompt')}</h1></div>
      </div>,
      <h3 key="styles">{t('.styles')}</h3>,
      TextileHelpModal.compare(
        `*${bold}*\n\n_${italic}_\n\n%(blockquote)${quote}%`,
        [
          <p key="bold"><strong>{bold}</strong></p>,
          <p key="italic"><em>{italic}</em></p>,
          <blockquote className="blockquote" key="blockquote">
            <p>{quote}</p>
          </blockquote>,
        ],
      ),
      <h3 key="headers">Headers</h3>,
      TextileHelpModal.compare(
        `h1. ${heading1}\n\nh2. ${heading2}\n\nh3. ${heading3}`,
        [
          <h1 key="h1">{heading1}</h1>,
          <h2 key="h2">{heading2}</h2>,
          <h3 key="h3">{heading3}</h3>,
        ],
      ),
      <h3 key="lists">Lists</h3>,
      TextileHelpModal.compare(
        '* first item\n* second item\n** sub item\n\n# number 1\n# number 2\n## number 2.1',
        [
          <ul key="ul">
            <li>first item</li>
            <li>
              second item
              <ul>
                <li>sub item</li>
              </ul>
            </li>
          </ul>,
          <ol key="ol">
            <li>number 1</li>
            <li>
              number 2
              <ol>
                <li>number 2.1</li>
              </ol>
            </li>
          </ol>,
        ],
      ),
      <h3 key="links">{link}</h3>,
      TextileHelpModal.compare(linksPre, linksHtml),
      <h3 key="images">{image}</h3>,
      TextileHelpModal.compare(
        '!https://media.giphy.com/media/2IodXMfbcVVrW/giphy.gif!',
        <p><img src="https://media.giphy.com/media/2IodXMfbcVVrW/giphy.gif" alt="" width="100%" /></p>,
      ),
      <h3 key="tables">{table}</h3>,
      TextileHelpModal.compare(
        `table(table table-bordered).\n|_.${name}|_.${age}|\n|Jason|3|\n|Mary|4|`,
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>{name}</th>
              <th>{age}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Jason</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Mary</td>
              <td>4</td>
            </tr>
          </tbody>
        </table>,
      ),
      (!extended ? null : (<h3 key="videos">{youtube}</h3>)),
      (!extended ? null : (TextileHelpModal.compare(
        'youtube. https://www.youtube.com/watch?v=GcgN0lEh5IA',
        <iframe
          width="100%"
          src="//www.youtube.com/embed/GcgN0lEh5IA?controls=2&amp;showinfo=0&amp;rel=0"
          frameBorder="0"
          allowFullScreen=""
          title="youtube-example"
        />,
      ))),
    ]
  }

  render() {
    const { onClose } = this.props
    return (
      <Modal title={t('.title')} onClose={onClose} className="TextileHelpModal" saveable={false}>
        <span dangerouslySetInnerHTML={{ __html: t('.intro') }} />
        {this.renderComparisons()}
      </Modal>
    )
  }
}
