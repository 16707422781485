import {
  addIndex,
  adjust,
  append,
  assoc,
  assocPath,
  complement,
  compose,
  concat,
  curry,
  curryN,
  equals,
  flip,
  fromPairs,
  has,
  head,
  identity,
  indexBy,
  insert,
  is,
  isEmpty,
  keys,
  length,
  map,
  path,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
  reduce,
  reject,
  remove,
  reverse,
  transduce,
} from 'shared/ramda_loader'
import { isNil } from 'shared/ramda_helpers_flow'

export const propOrNull = propOr(null)

export const propIn = curry((field, list, value) => list.includes(value[field]))

/**
 * We can't use `toString` destructured from ramda in our tests because the rewire
 * plugin screws it up sadly, so we have to remove that dependency before we can use the native ramda
 * functionality there.
 */
const toStringC = x => (x === undefined ? 'undefined' : x === null ? 'null' : x.toString())

// alias
export const first = head

// grabs the IDs of a collection of objects
export const idsOf = map(prop('id'))

export const propTruthy = curry((field, value) => !!value[field])
export const isNotNil = complement(isNil)
export const propIsNotNil = compose(isNotNil, prop)

export const isNotEmpty = complement(isEmpty)

export const notEquals = complement(equals)

/**
 * 'Updates' a value in a nested associative structure, where path is a
 * sequence of keys and fn is a function that will take the old value
 * and return the new value. If any levels do not exist, hash-maps will be
 * created.
 *
 * @param {Array.<*>} path list of keys into the structure
 * @param {function} fn function to apply to value at path
 * @param {object} [collection] object/array to update
 * @returns {Array.<*>} new object/array
 */
export const updateIn = curry((pth, fn, collection) => {
  const val = path(pth, collection)
  return assocPath(pth, fn(val), collection)
})

/**
 * Usually updateIn works the way you'd expect. However, we've found that for dictionaries that have negative integer
 * keys (e.g. { [-1]: { id: -1, name: 'Foo } }), the `path` lookup will fail in an unexpected way. `path` requires
 * an array when given a negative integer. https://github.com/ramda/ramda/issues/3007
 * This function stringifies the path, but this does means it does not play nicely with arrays.
 */
export const updateInStr = curry((pth, fn, collection) => {
  const val = path(map(toStringC, pth), collection)
  return assocPath(pth, fn(val), collection)
})

/**
 * move element with index 'from' -> index 'to' in list
 *
 * @param {number} from
 * @param {number} to
 * @param {Array.<*>} list
 * @returns {Array.<*>} new list
 */
export const moveInList = curry((from, to, list) => {
  const item = list[from]
  return pipe(
    remove(from, 1),
    insert(to, item),
  )(list)
})

/**
 * return the first index where fn(x) is true
 * @param {function} fn
 * @param {Array.<*>} list
 */
export const indexWhere = curry((fn, list) => {
  for (let i = 0; i < list.length; i++) {
    if (fn(list[i])) return i
  }
  return -1
})

// performs the operation on the collection when the item is found
export const whenPresent = (finder, x, xs, operation) => {
  const i = finder(x, xs)
  return i !== -1 ? operation(i, xs) : xs
}

/**
 * Creates a lookup function that can be provided in map, etc.
 *
 * Usage:
 *
 *    const lessonsLookup = lookup(lessonsIndex)
 *    const lessons = map(lessonsLookup, lessonIds)
 */
export const lookup = indexedCollection => compose(
  flip(prop)(indexedCollection),
  /**
   * The lookups will differ if given a number of a string.
   * See more here: https://github.com/ramda/ramda/issues/2543#issuecomment-392053756
   * We're expecting an object for this lookup behavior, so stringify incoming lookup keys to ensure
   * it looks up as an object rather than an array index.
   */
  toStringC,
)

/**
 * takes an indexed collection and a set of ids, and turns the ids -> objects
 */
export const hydrate = curry((indexedCollection, ids) => map(lookup(indexedCollection), ids))

// todo: we should reorder args to work this way, let's deprecate hydrate and use this one instead
export const hydrate2 = curry((ids, indexedCollection) => map(lookup(indexedCollection), ids))

export const globalizeSelector = transform => selector => (
  curryN(length(selector),
    (...args) => selector(...adjust(-1, transform, args)),
  )
)

/**
 * turns local selectors -> global selectors see
 * http://randycoulman.com/blog/2016/12/27/globalizing-curried-selectors/
 * @param localStateTransform
 * @param selectors
 */
export const globalizeSelectors = (localStateTransform, selectors) =>
  map(globalizeSelector(localStateTransform), selectors)

/**
 * just like pipe, except it returns an array of all the states that were passed in
 * @param xforms
 */
export const pipeAndRetain = (...xforms) => (state) => {
  const states = [state]
  xforms.forEach((xform) => {
    state = xform(state) // eslint-disable-line no-param-reassign
    states.push(state)
  })
  return states
}

/**
 * Passes through the function. Useful for connector functions.
 */
export const passThrough = curry(a => a)

/**
 * index several objects by their id
 */
export const indexById = indexBy(prop('id'))

export const removeById = curry((id, xs) => reject(propEq('id', id), xs))

/**
 * generate a hash of ids -> positions
 * @param ids an array of ordered ids
 */
export const indexPositionsById = ids => fromPairs(addIndex(map)((id, i) => ([id, i]))(ids))

export const mapIndexed = addIndex(map)

export const compact = reject(isNil)

export const isPromise = x => x && typeof x.then === 'function'

/**
 * Takes an array of operations that take a list, transduces them, and returns the resulting list.
 * Operations are applies left-to-right, even though `compose` is used... don't quite
 * understand why.
 */
export const transduceList = (operations, list) => (
  transduce(pipe(...reverse(operations)), flip(append), [], list)
)

/**
 * Allows you to map indexed over an object nested two levels deep, something like: { 1: { 10: 'foo' } }
 */
export const deepMapObjIndexed = curry((fn, obj) => (
  reduce(
    (acc, key1) => concat(acc, map(key2 => fn(obj[key1][key2], key1, key2), keys(obj[key1]))),
    [],
    keys(obj),
  )
))

// doesn't die if either or both lists are nil, but defaults them to empty lists
export const concatSafe = curry((a, b) => concat(a || [], b || []))

export const deepValues = obj => deepMapObjIndexed(identity, obj)

export const deepReduceObjIndexed = (fn, init, obj) => (
  reduce((acc, vs) => fn(acc, ...vs), init, deepMapObjIndexed((val, k1, k2) => [val, k1, k2], obj))
)

/**
 * Accepts an array of tuples, of the form:
 *
 * [firstIndex, secondIndex, item]
 *
 * which returns an indexed object, like:
 *
 * {
 *   firstIndex: {
 *     secondIndex: item
 *   }
 * }
 */
export const indexByXAndY = items => (
  reduce(
    (acc, [x, y, change]) => assocPath([x.toString(), y.toString()], change, acc),
    {},
    items,
  )
)

export const arrayify = xs => (isNil(xs) || is(Array, xs) ? xs : [xs])

// reduces across a collection, adding more items indexed by the ID of the item.
export const addItemsToIndexById = reduce((obj, x) => assoc(x.id, x, obj))

export const isTrue = Boolean

export const hasInPath = curry((key, p, obj) => has(key, pathOr({}, p, obj)))

/**
 * Takes two functions and a parameter. Parameter is given to the second function, and then the result of that and the
 * original parameter are given to the first function.
 *
 * Same as (f, g) => converge(f, [g, identity]) in Ramda.
 *
 * Inspiration: https://jrsinclair.com/articles/2019/compose-js-functions-multiple-parameters/
 */
export const spread = curry((f, g, x) => f(g(x), x))
