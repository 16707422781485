import qs from 'qs'
import I18n from 'i18n-js'
import { concat, flip, isEmpty, map, omit, pipe, toPairs, when } from 'shared/ramda_loader'
import { toSentence } from 'shared/string_helpers'

export const parameterizeRoute = route => (...params) => {
  /**
   * regex captures all letters (plus underscores) after a colon, so /:foo_bar/whatever -> [':foo_bar']
   * Also deals with capturing colons in query parameters, which are encoded as `%3A`, so
   * /foo?query=:bar would be sent down as /foo?query=%3Abar
   */
  const matches = route.match(/((:|%3A)[a-zA-Z|_]+)/g)
  if (!matches) return route
  if (matches.length !== params.length) {
    throw new Error(`Not enough parameters provided to the route, expected ${toSentence(matches)}`)
  }
  return matches.reduce(
    (acc, match, i) => acc.replace(match, (params[i])),
    route,
  )
}

export const Routes = {}

export const setupRoutes = () => {
  pipe(
    toPairs,
    map(([helper, route]) => Routes[helper] = parameterizeRoute(route)),
  )(window.RailsRoutes || {})
}

/**
 * Appends a format to the URL. Defaults to json when no format is provided; allows `null` to be specified when no
 * format should be appended, otherwise adds the format specified.
 */
const addFormat = (format) => {
  if (format === null) return ''
  if (format === undefined) return '.json'
  return `.${format}`
}

export const joinUrlParts = (...parts) => {
  const start = parts[0].replace(/\/$/, '')
  const rest = parts.slice(1).map(part => part.replace(/^\//, ''))
  return [start, ...rest].join('/')
}

export const removeSchoolIdFromUrl = (url = '') => {
  if (url.startsWith('http')) throw new Error('URL looks like an absolute path; a relative one was expected')
  return url.replace(/^\/s\/\d+/, '')
}

export const buildUrl = (url, options = {}) => {
  const locale = I18n.locale
  const query = omit(['format'], locale ? { locale, ...options } : options)
  return (
    pipe(
      flip(concat)(addFormat(options.format)),
      when(_ => !isEmpty(query), flip(concat)(`?${qs.stringify(query, { encode: false, arrayFormat: 'brackets' })}`)),
    )(url)
  )
}

/**
 * Paths
 *
 * Behaves similarly to Rails paths. Defaults to JSON format unless otherwise specified. Example usages:
 *
 * classroomPhotosPath(42) // 'classrooms/42/posts.json'
 * classroomPhotosPath(42, { format: null }) // 'classrooms/42/posts'
 * classroomPhotosPath(42, { format: 'csv', child_ids: [1] } // 'classrooms/42/posts.json?child_ids[]=1
 */

export const classroomPath = (classroomId, options) => (
  buildUrl(`classrooms/${classroomId}`, options)
)

export const classroomChildrenPath = (classroomId, options) => (
  buildUrl(`classrooms/${classroomId}/children`, options)
)

export const classroomPostsPath = (classroomId, options) => (
  buildUrl(`classrooms/${classroomId}/posts`, options)
)

export const classroomStandardLevelsPath = (classroomId, options) => (
  buildUrl(`classrooms/${classroomId}/standard_levels`, options)
)

export const childStandardPostsPath = (childId, standardId, options) => (
  buildUrl(`children/${childId}/standards/${standardId}/posts`, options)
)

export const standardsProgressLessonSetLessonsPath = (lessonSetId, lessonId, options) => (
  buildUrl(`lesson_sets/${lessonSetId}/lessons/${lessonId}/standards_progress`, options)
)

export const graphSessionClassroomPlacementPath = (sessionId, options) => (
  buildUrl(`sessions/${sessionId}/classroom_placement/graph`, options)
)

export const onlineApplicationPath = (id, options) => (
  buildUrl(`online_applications/${id}`, options)
)

export const createChildAndAcceptApplicationPath = (applicationId, options) => (
  buildUrl(`online_applications/${applicationId}/create_child_and_accept`, options)
)

export const createChildWithParentsAndAcceptApplicationPath = (applicationId, options) => (
  buildUrl(`online_applications/${applicationId}/create_child_with_parents_and_accept`, options)
)
