let instance = null;

export const setSentryInstance = (newInstance) => {
  instance = newInstance;
};

export const getInstance = () => {
  if (instance) {
    return instance;
  } else {
    throw new Error('setSentryInstance needs to be called');
  }
};

/**
 * @param {String} message
 * @param {*} metadata
 */
export function captureMessage(message, metadata) {
  getInstance().captureMessage(message, metadata);
}

export const captureException = (e, metadata) => {
  getInstance().captureException(e, { ignoreJSErrorModal: true, ...metadata });
};

// https://docs.sentry.io/platforms/react-native/enriching-events/identify-user/
export const setUserContext = user => getInstance().setUser(user);

export class SentryContext {
  static setUser(user) {
    setUserContext({
      id: user.id,
      email: user.email || '',
      firstName: user.first_name,
      fullName: `${user.first_name} ${user.last_name}`,
    });
  }

  static forgetUser() {
    // based off https://docs.sentry.io/platforms/react-native/enriching-events/identify-user/
    setUserContext(null);
  }
}
