import debounce from 'lodash/debounce'
import each from 'lodash/each'
import { append, forEach, insert, map, sum } from 'shared/ramda_loader'

// this will return the width of a widget the first time, and after that, it will
// cache the width so that it is fast to compute later
const width = ($el) => {
  if ($el.length === 0) return 0

  let w = $el.attr('data-width')
  if (w) return parseInt(w)

  w = $el.width()
  if (w > 0) $el.attr('data-width', w)

  return w
}

const addNavLink = ($navLink, navLinks) => (
  $navLink.hasClass('active') ? insert(0, $navLink, navLinks) : append($navLink, navLinks)
)

const isVisible = $el => $el.is(':visible')

let $secondaryNav
let $focusSelector
let $linkContainer
let $filters
let $more
let $moreDropdown

function init() {
  $secondaryNav = $secondaryNav || $('.secondary-nav')
  $focusSelector = $focusSelector || $('.secondary-nav__focus_selector')
  $linkContainer = $linkContainer || $('.secondary-nav__links')
  $filters = $filters || $('.secondary-nav__filter')
  $more = $more || $linkContainer.find('.more-secondary-nav')
  $moreDropdown = $moreDropdown || $('#more-secondary-nav-dropdown')
}

/**
 * Allows dynamically manipulating primary and secondary nav bars.
 */
function autosizeSecondary() {
  init()

  let availableSpace = $secondaryNav.width() - sum(map(width, [$focusSelector, $filters, $more]))

  let shouldRedrawMoreDropdown = false
  let navLinks = []
  forEach(el => navLinks = addNavLink($(el), navLinks), $linkContainer.children('.secondary-nav-link'))

  let reachedCapacity = false // hide all following links after reaching capacity
  forEach(($navLink) => {
    if (availableSpace > width($navLink) && !reachedCapacity) {
      if (!isVisible($navLink)) {
        $navLink.show()
        shouldRedrawMoreDropdown = true
      }
      availableSpace -= width($navLink)
    } else {
      if (isVisible($navLink)) {
        $navLink.hide()
        shouldRedrawMoreDropdown = true
      }
      reachedCapacity = true
    }
  }, navLinks)

  if (shouldRedrawMoreDropdown) {
    const $hidden = $linkContainer.find('.secondary-nav-link:hidden')
    $more.toggle($hidden.length > 0)
    let items = ''
    each($hidden, (e) => {
      const href = $('a', e).attr('href')
      const text = $(e).text()
      items += `<a href="${href}" class="dropdown-item">${text}</a>`
    })
    $more.find('.dropdown-menu').html(items)
  }
}

/**
 * Adds an item to the end of the nav bar.
 *
 * @param title the name to display in the menu item
 * @param href the href link for the item
 * @param icon (optional) the FA icon name (only for primary nav)
 */
function addPrimary(title, href, icon) {
  const $item = $('<a>', {
    class: 'primary-nav-link',
    title,
    href,
  })

  if (icon) $item.append($(`<i class="fas fa-${icon}" />`))

  $item.append($(`<span>${title}</span>`))

  $('.primary-nav').append($item)
}

/**
 * Adds an item to the end of the nav bar.
 *
 * @param title the name to display in the menu item
 * @param href the href link for the item
 * @param icon (optional) the FA icon name (only for primary nav)
 */
function addSecondary(title, href, icon) {
  init()
  // if there's no secondary nav, nothing to do
  if ($secondaryNav.length === 0) return

  const $item = $('<div>', { class: 'secondary-nav-link' })
    .append($('<a>', { href }).text(title))

  $item.insertBefore($linkContainer.find('.more-secondary-nav'))
}

function startAutosizingSecondary() {
  $(window).resize(debounce(autosizeSecondary, 100))
  // wait until everything is finished loading before resizing
  $(() => autosizeSecondary())
  $(document).on('primary-nav-change', autosizeSecondary)
}

function selectSecondary(id) {
  $('.secondary-nav-link.active').removeClass('active')
  const $link = $(`.secondary-nav-link[data-id=${id}]`)
  $link.addClass('active')
}

export const primary = {
  add: addPrimary,
}

export const secondary = {
  autosize: autosizeSecondary,
  startAutosizing: startAutosizingSecondary,
  add: addSecondary,
  select: selectSecondary,
}
