import moment from 'moment'
import { compose, identity, isNil, memoizeWith, pipe } from 'shared/ramda_loader'
import { capitalize, isBlank } from './string_helpers'
import { tForFile } from 'shared/i18n'
import { isPresent } from 'shared/./ramda_helpers_flow'

export const db = 'YYYY-MM-DD'

const t = tForFile('web.shared.date_utils')

const DAY_TO_DAY_NUMBER = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
}

const DAY_NUMBER_TO_DAY_STRING = {
  [0]: 'Sun',
  [1]: 'Mon',
  [2]: 'Tue',
  [3]: 'Wed',
  [4]: 'Thu',
  [5]: 'Fri',
  [6]: 'Sat',
}

/**
 * We seem to be hardcoding the starts of weeks, probably should be looking at locale instead
 * https://app.clubhouse.io/transparentclassroom/story/2941
 * momentjs supports both ways, either with `.day` or `.isoWeekday`.
 */

/**
 * Starts with Sunday as 0. Uses same pattern as moment.js. These shortnames are NOT TRANSLATED.
 */
export const dateToDayShortname = date => (
  // TODO use the safer moment?
  isNil(date) ? '' : DAY_NUMBER_TO_DAY_STRING[moment(date).day() % 7]
)

/**
 * Takes a day shortname (e.g. Wed) and turns it into a formatted date string of this week, using `db` as the
 * format pattern.
 */
export const dayToDate = (day, startingWeek) => {
  // A planned level without a date is a null, so return null here when the day is for unplanned column
  if (day === '') return null

  // `#day` SETS the day, so we need to clone the week to avoid mutation
  return ((startingWeek && startingWeek.clone()) || moment()).day(DAY_TO_DAY_NUMBER[day]).format(db)
}

export const currentDateFormat = () => window.$.fn.datepicker.dates[window.I18n.locale].format.toUpperCase()

export const momentMaybe = date => (
  isBlank(date) ? null : moment(date)
)

// TODO rename to `momentFromLocalString`
export const newValidMomentOrThrow = (date) => {
  if (!date) return null

  const m = moment(date, currentDateFormat(), true)
  if (!m.isValid()) {
    throw new Error(`Invalid date string '${date}' for format ${currentDateFormat()}`)
  }
  return m
}

export const formatForBootstrapDatepicker = m => m.format(currentDateFormat())
export const formatForBootstrapDatepickerMaybe = m => (
  isPresent(m) ? m.format(currentDateFormat()) : ''
)

export const convertToBootstrapDate = compose(formatForBootstrapDatepickerMaybe, momentMaybe)

/**
 * Returns the day and full date, e.g. Sunday, 12/20/2017
 */
export const formatDateDayAndL = val => (
  capitalize(moment(val).format('dddd, L'))
)

/**
 * this corresponds to default format in date.en.yml
 */
export function formatDateL(val) {
  return moment(val).format('l')
}

const convertFormatFromRubyToMoment = format => (
  format
    .replace('%e', 'D')
    .replace('%-e', 'D')
    .replace('%b', 'MMM')
    .replace('%Y', 'YYYY')
)

const shortDateFormat = memoizeWith(identity, () => (
  convertFormatFromRubyToMoment(t('date.formats.short_date'))
))

const shortDateWithYearFormat = memoizeWith(identity, () => (
  convertFormatFromRubyToMoment(t('date.formats.short_date_with_year'))
))

export const formatDateShortDate = (val) => {
  const date = moment(val)
  return date.format(date.year() === moment().year() ? shortDateFormat() : shortDateWithYearFormat())
}

/**
 * Returns locale-aware short date, i.e. DD/MM or MM/DD. Note the divider may vary based on locale.
 */
export const formatDateMonthDay = val => (
  moment(val).format(currentDateFormat().replace(/-?\.?\/?YYYY/, '').replace(/MM/, 'M').replace(/DD/, 'D'))
)

/**
 * Returns format: February 24, 2017
 */
export function formatDateLL(val) {
  return moment(val).format('LL')
}

/**
 * Returns format: Feb. 24, 2017
 */
export function formatDatell(val) {
  return moment(val).format('ll')
}

/**
 * Returns format YYYY-MM-DD
 */
export const formatDateDB = date => (date ? moment(date).format(db) : null)

/**
 * Returns day shortname and date short, like "Sun, 12/10"
 */
export const formatDateDayShortnameAndShortDate = (m) => {
  const dayShort = pipe(
    x => x.format('ddd'),
    capitalize,
    x => x.replace(/\./, ''),
  )(m)
  const dateShort = formatDateMonthDay(m)
  return `${dayShort}, ${dateShort}`
}

export const isInSameWeek = (moment1, moment2) => {
  if ((moment1 && !moment2) || (!moment1 && moment2)) return false
  return formatDateDB(moment1.clone().day(0)) === formatDateDB(moment2.clone().day(0))
}

/**
 * Useful for setting the day of week on a moment. We need to translate to a day number (i.e. 0 for Sunday) because
 * day names (e.g. "Sunday") are localized, so saying .day('Sunday') does not work in non-English locales
 */
export const momentOnDayOfWeek = (day, m) => moment(m).day(DAY_TO_DAY_NUMBER[day])

export const isSameDate = (m1, m2) => formatDateDB(m1) === formatDateDB(m2)

/**
 * Returns a moment from next week, between Monday and Friday.
 */
export const nextWeekOnAWeekday = () => (
  moment().add(1, 'week').day(Math.max(1, Math.min(5, moment().day())))
)

// Returns a set of minutes and seconds remaining in an window interval of minutes
export const timeLeft = (interval, minutes, seconds) => ({
  minutes: (minutes % interval === 0 && seconds === 0) ? 0 : interval - (minutes % interval) - (seconds > 0 ? 1 : 0),
  seconds: seconds === 0 ? 0 : 60 - seconds,
})

// Returns the difference in dates, formatted like '1yr 8mo'. Can return negative values, e.g. '-0yr 8mo'
export const ageAtSessionStart = (sessionStart, childBirthDate) => {
  if (!childBirthDate) {
    return '(age unknown)'
  }

  const monthsDiff = moment(sessionStart).diff(moment(childBirthDate), 'months')
  const sign = monthsDiff < 0 ? '-' : ''
  return `${sign}${Math.floor(Math.abs(monthsDiff) / 12) + t('.years_abbrev')} ` +
    `${(Math.abs(monthsDiff % 12)) + t('.months_abbrev')}`
}

export const differenceInDates = (date1, date2) => {
  const [earlierMoment, laterMoment] = [date1, date2].sort().map(date => moment(date))
  return laterMoment.diff(earlierMoment)
}

export const is24HourClock = () => moment('2018-01-01 14:00').format('LT') === '14:00'

export const __RewireAPI__ = {}
