/* eslint-disable react/prop-types */
import React from 'react'
import { Icon } from './icon'
import './button.scss'
import { noop } from 'shared/noop'
import { omit } from 'shared/ramda_loader'

const defaultProps = {
  className: '',
  onClick: noop,
  type: 'secondary',
  text: '',
  textColor: '',
}

export const Button = (props) => {
  const {
    block, className, dropdown, dropdownNoCaret, flat, form, icon, iconModifiers, iconStyle, loading, onClick, naked,
    size, text, textColor, title, type, disabled, uppercase, modalTarget, rightIcon, ...rest
  } = props
  let btnClass = className
  btnClass += ' Btn btn'
  if (naked) {
    // do nothing, add no additional button type styling
  } else if (type.match('link-lite')) {
    btnClass += ` btn-link btn-${type}`
  } else if (flat) {
    btnClass += ` btn-outline-${type}`
  } else if (icon && !text) {
    btnClass += ' btn-icon'
  } else {
    btnClass += ` btn-${type}`
  }
  if (icon) btnClass += ' manual-casing'
  if (size) btnClass += ` btn-${size}`
  if (block) btnClass += ' btn-block'
  if (dropdown && !dropdownNoCaret) btnClass += ' dropdown-toggle'

  let dataToggle
  if (dropdown) {
    dataToggle = 'dropdown'
  } else if (modalTarget) {
    dataToggle = 'modal'
  }

  return (
    <button
      type={form ? 'submit' : 'button'}
      className={btnClass}
      onClick={onClick}
      title={title}
      disabled={disabled || loading}
      data-toggle={dataToggle}
      data-target={modalTarget}
      aria-haspopup={dropdown}
      aria-expanded={dropdown ? false : null}
      {...omit(['data-test'], rest)}
    >
      {icon && (
        <Icon
          className={text ? 'Btn__icon--with-text' : ''}
          icon={icon}
          iconModifiers={iconModifiers}
          iconStyle={iconStyle}
        />
      )}
      {text && (
        <span className="Btn__text" style={{ color: textColor }}>{uppercase ? (text).toUpperCase() : text}</span>
      )}
      {loading ? (
        <Icon icon="spinner" iconModifiers={['pulse', 'spin']} className={text ? 'ml-b' : ''} />
      ) : (
        rightIcon && (
          <Icon className={text ? 'ml-b' : ''} icon={rightIcon} iconModifiers={iconModifiers} />
        ))}
    </button>
  )
}

Button.defaultProps = defaultProps
