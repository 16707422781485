import React from 'react'
import autosize from 'autosize'
import { omit } from 'shared/ramda_loader'
import cn from 'classnames'
import { check } from 'shared/flow_helpers'
import { noop } from 'shared/noop'

export class Textarea extends React.PureComponent {
  static defaultProps = {
    autogrow: false,
    className: '',
    initialText: '',
    focused: false,
    onChange: noop,
  }

  state = {
    text: this.props.initialText,
  }

  componentDidMount() {
    if (this.props.focused) {
      const textarea = check(this.textarea)
      textarea.selectionEnd = 0
      textarea.selectionStart = 0
      textarea.focus()
      textarea.dispatchEvent(new Event('keypress'))
    }
    if (this.props.autogrow) {
      autosize(this.textarea)
    }
  }


  handleChange = (e) => {
    const text = e.target.value
    this.setState({ text }, () => {
      this.props.onChange(text)
    })
  }

  sync = () => {
    this.setState({ text: check(this.textarea).value })
  }

  focus = () => check(this.textarea).focus()

  text = () => this.state.text

  setText = (text) => {
    this.handleChange({ target: { value: text } })
  }

  render() {
    const { className, fieldName, ...rest } = this.props
    const { text } = this.state
    return (
      <textarea
        ref={e => (this.textarea = e)}
        className={cn('form-control', className)}
        value={text}
        name={fieldName}
        onChange={this.handleChange}
        data-test="dt-Textarea-textarea"
        {...omit(['initialText', 'focused', 'onChange', 'autogrow'], rest)}
      />
    )
  }
}
