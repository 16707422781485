import $ from 'jquery'
import 'popper.js'
import '../vendor/bootstrap/dist/js/bootstrap'
import 'chosen-js/chosen.jquery.js'
import 'jquery-validation/dist/jquery.validate.js'
import 'jquery-validation/dist/additional-methods'
import '../vendor/fancybox/jquery.fancybox'
import '../vendor/bootstrap-datepicker/bootstrap-datepicker'
import '../vendor/bootstrap-sheet/bootstrap-sheet'
import '../vendor/bootstrap3-timepicker2/bootstrap-timepicker'

$.fn.datepicker.defaults.startDate = new Date(1950, 0, 1)
$.fn.datepicker.defaults.endDate = new Date(2100, 0, 1)
