/* eslint-disable react/prop-types */
import React from 'react'
import { toPairs } from 'shared/ramda_loader'
import * as formatter from './formatter'
import { tForFile } from 'shared/i18n'

import TextileHelpModal from './textile_help_modal'
import TextilePreview from './textile_preview'
import { Button } from 'modules/bootstrap_components/components/button'
import { Textarea } from 'modules/form_controls/components/textarea'
import { noop } from '../../noop'

import './textile_textarea.scss'
import { check } from 'shared/flow_helpers'
import { ButtonDropdown } from 'modules/bootstrap_components/components/button_dropdown'

const t = tForFile('web.shared.components.textile_textarea.textile_textarea')

export default class TextileTextarea extends React.PureComponent {
  static defaultProps = {
    className: '',
    customMarkup: {},
    focused: false,
    onChange: noop,
  }

  state = {
    showHelp: false,
    textForPreview: this.props.text,
  }

  // refs
  textarea

  focus = () => this.textarea.focus()

  handler = (fn, ...args) => (e) => {
    e.preventDefault()
    const textarea = check(this.textarea.textarea)
    fn(textarea, ...args)
    this.textarea.sync() // have to sync otherwise, it will not keep new value on re-render
    this.handleChange()
    this.textarea.focus()
  }

  handleChange = () => {
    const textarea = check(this.textarea.textarea)
    this.setState({ textForPreview: textarea.value })
    this.props.onChange(({ target: textarea }))
  }

  text = () => this.textarea.text()

  render() {
    const { name, disabled, className, extended, focused, rows, text, tabIndex, customMarkup, previewUrl } = this.props
    const { showHelp, textForPreview } = this.state

    /* eslint-disable jsx-a11y/href-no-hash */
    return (
      <div className="TextileTextarea">
        <div className="row">
          <div className="col-xl-6 col-lg-7 col-md-8">
            {!disabled && (
              <div className="TextileTextarea__toolbar d-flex flex-wrap mb-2">
                <Button icon="bold" onClick={this.handler(formatter.bold)} title={t('.bold')} />
                <Button icon="italic" onClick={this.handler(formatter.italic)} title={t('.italic')} />
                <Button icon="underline" onClick={this.handler(formatter.underline)} title={t('.underline')} />
                <ButtonDropdown
                  noCaret
                  icon="heading"
                  items={[
                    <a href="#" onClick={this.handler(formatter.heading, 1)}>{t('.heading_1')}</a>,
                    <a href="#" onClick={this.handler(formatter.heading, 2)}>{t('.heading_2')}</a>,
                    <a href="#" onClick={this.handler(formatter.heading, 3)}>{t('.heading_3')}</a>,
                  ]}
                />

                <Button icon="list-ol" onClick={this.handler(formatter.orderedList)} title={t('.ordered_list')} />
                <Button
                  icon="list-ul"
                  onClick={this.handler(formatter.unorderedList)}
                  title={t('.unordered_list')}
                />
                <Button icon="table" onClick={this.handler(formatter.table)} title={t('.table')} />
                <Button icon="link" onClick={this.handler(formatter.link)} title={t('.link')} />
                <Button
                  icon="image"
                  iconStyle="regular"
                  onClick={this.handler(formatter.image)}
                  title={t('.image')}
                />
                <Button icon="quote-right" onClick={this.handler(formatter.blockQuote)} title={t('.blockquote')} />
                {extended && (
                  <Button
                    icon="youtube"
                    iconStyle="brand"
                    onClick={this.handler(formatter.youtube)}
                    title={t('.youtube')}
                  />
                )}
                {toPairs(customMarkup).map(([icon, options]) => (
                  <ButtonDropdown
                    key={icon}
                    icon={icon}
                    noCaret
                    iconStyle="regular"
                    items={options.map(([optionLabel, value]) => (
                      <a href="#" onClick={this.handler(formatter.insert, value)}>{optionLabel}</a>
                    ))}
                  />
                ))}
                <Button
                  icon="question-circle"
                  onClick={() => { this.setState({ showHelp: true }) }}
                  title={t('.help')}
                  data-test="dt-TextileTextarea-help"
                />
              </div>
            )}

            <Textarea
              ref={e => (this.textarea = e)}
              className={className}
              autogrow
              name={name}
              initialText={text || ''}
              rows={rows}
              tabIndex={tabIndex}
              focused={focused}
              onChange={this.handleChange}
              disabled={disabled}
            />
          </div>
          <div className="col-xl-6 col-lg-5 col-md-4">
            <TextilePreview textForPreview={textForPreview} renderUrl={previewUrl} />
          </div>
        </div>

        {showHelp && (
          <TextileHelpModal
            extended={extended}
            onClose={() => { this.setState({ showHelp: false }) }}
            data-test="dt-TextileTextarea-help-modal"
          />
        )}
      </div>
    )
  }
}
