import { application } from './application'

import ConferenceReports__ChoosePhotos__EditController from './conference_reports/choose_photos/edit_controller.js'
import DismissableController from './dismissable_controller.js'
import FcmRegistrationController from './fcm_registration_controller.js'
import FcmDeregistrationController from './fcm_deregistration_controller.js'
import FormValidationController from './form_validation_controller.js'
import SectionMenuController from './section_menu_controller.js'
import ShowHideController from './show_hide_controller.js'
import BulkPhotoUploadController from './bulk_photo_upload_controller.js'
import SignatureController from './signature_controller.js'

application.register('conference-reports--choose-photos--edit', ConferenceReports__ChoosePhotos__EditController)
application.register('dismissable', DismissableController)
application.register('fcm-registration', FcmRegistrationController)
application.register('fcm-deregistration', FcmDeregistrationController)
application.register('form-validation', FormValidationController)
application.register('section-menu', SectionMenuController)
application.register('show-hide', ShowHideController)
application.register('bulk-photo-upload', BulkPhotoUploadController)
application.register('signature', SignatureController)

