import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    cookieName: { type: String, default: 'default' },
  }

  dismiss() {
    document.cookie = `${this.cookieNameValue}=true;max-age=604800` // one week
    this.element.remove();
  }
}
