import { tenum } from 'shared/i18n'
import { isPresent } from 'shared/string_helpers'

export const DAILY_TRACKING_STATE_NAME = 'daily_tracking'
export const UPDATE_RECORDING_IN_PROGRESS = 'daily_tracking/UPDATE_RECORDING_IN_PROGRESS'

export const CONCENTRATION_CHOICES = [3, 2, 1, 0, -1, -2]

export const concentrationChoiceName = i => tenum('concentration_choices', `choice_${i}`)

// We might change how to change concentration values again, so collect in one function for easy change
export const concentrationEventDisplay = (i) => {
  const choiceLabel = concentrationChoiceName(i)
  return `${i} ${isPresent(choiceLabel) ? ` (${choiceLabel})` : ''}`
}
