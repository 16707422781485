import { setSentryInstance } from 'shared/sentry';
import * as Sentry from '@sentry/browser';

export function initializeSentry() {
  if (!window.tcSentry) {
    setSentryInstance({
      captureException: () => {},
      setUser: () => {},
      captureMessage: () => {},
    });
    return;
  }

  const updatedSentryConfig = {
    ...window.tcSentry.config,
    integrations: [],
    ignoreErrors: [
      // Seems like an error caused by iOS ?
      "Can't find variable: setIOSParameters",
      // iOS Bug doesnt handle aborts gracefully https://bugs.webkit.org/show_bug.cgi?id=215771
      'AbortError: Fetch is aborted',
      // internal chrome error: http://bit.ly/2nln6Fh
      "undefined is not an object (evaluating '__gCrWeb.autofill.extractForms')",
      // caused on Chrome mobile app, would trigger when editing any form field :-(
      '__gCrWeb.form.wasEditedByUser',
      // happens a lot on generic android phones
      "Failed to read the 'cssRules' property from 'CSSStyleSheet': Cannot access rules",
      // seem to happen when js doesn't fully load???
      "Can't find variable: $",
      "Can't find variable: tc",
      "'tc' is not defined",
      'tc is not defined',
      "'$' is not defined",
      '$ is not defined',
      "Cannot set property 'env' of undefined",
      'Blocked a frame with origin "https://www.transparentclassroom.com" from accessing a frame with origin',
      "null is not an object (evaluating 'b.scrollWidth')",
      "Cannot read property 'clickAndSpeak' of undefined",
      "Cannot read property 'backgroundColor' of undefined",
      "Cannot read property 'foregroundColor' of undefined",
      "Cannot read property 'continuousReading' of undefined",
      // Ignore errors from Firefox iOS reader
      "evaluating 'window.__firefox__.reader.checkReadability'",
      "evaluating '__firefox__.metadata.extractMetadata'",
      "evaluating '__firefox__.favicons.getFavicons'",
      '__firefox__.searchQueryForField is not a function',
      // Ignore errors due to samsung internet bug: https://github.com/SamsungInternet/support/issues/56
      'document.getElementsByClassName.ToString',
      // Weird error on Firefox iOS
      "null is not an object (evaluating 'a.title')",
      // some adblocker or something...
      "document.getElementsByTagName('body')[0].appendChild",
      // benign error
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      // seems like error caused by people opening TC links in Zalo chat app maybe? https://sentry.io/organizations/transparentclassroom/issues/1214267241
      'zaloJSV2 is not defined',
      // https://sentry.io/organizations/transparentclassroom/issues/1719733897. Looks Android related, not us.
      "Cannot read property 'getReadModeRender' of undefined",
      "Cannot read property 'getReadModeExtract' of undefined",
      "Cannot read property 'getReadModeConfig' of undefined",
      'onScreenResumed is not defined',
      'onScreenPaused is not defined',
      // seems like a cosmetic error for Firefox iOS, Safari, or maybe both?
      // https://app.clubhouse.io/transparentclassroom/story/6172
      "undefined is not an object (evaluating 'webkit.messageHandlers.loginsManagerMessageHandler.postMessage')",
      // Ignore errors from Grammarly extension:
      // https://kb.tableau.com/articles/issue/Uncaught-Syntaxerror-Identifier-originalprompt-Has-Already-Been-Declared
      "Identifier 'originalPrompt' has already been declared",
      // This appears to be caused by bing instant search and has nothing to do with us:
      // @see https://stackoverflow.com/questions/69261499/what-is-instantsearchsdkjsbridgeclearhighlight
      "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
      'TypeError: Load failed',
      // Both of these AxiosErrors occur when HelpScout is having a hard time with their Beacon
      'AxiosError: Network Error',
      'AxiosError: Request failed with status code 400',
    ],
    shouldSendCallback(data) {
      // Ignore errors on WF network page
      if ((data.request && data.request.url.match(/\/networks\/wf/)) ||
        (data.request && data.request.url.match(/localhost:3000/))) {
        return false

        // Ignore errors from clients on IE
      } else if (window.detectIE() && window.detectIE() < 12) {
        return false
      } else {
        return data
      }
    },
    ignoreUrls: ['localhost', '127.0.0.1', 'beacon-v2.helpscout.net', 'helpscout.net', 'beaconapi.helpscout.net'],
    dataCallback(data) {
      /**
       * show our custom modal (shared/error_modal). There's a special pathway where manually capturing
       * exceptions for Sentry should not trigger the error modal, because presumably we're already
       * anticipating it and don't want the error to show.
       */
      if (!data.ignoreJSErrorModal) {
        $('#error-modal').modal('show')
      }
    },
  };

  Sentry.init(updatedSentryConfig);

  setSentryInstance(Sentry);
  window.Sentry = Sentry;
  window.tcSentry.afterInitialize();
}
