import { findEndOfLine, insertAt, replaceAt } from '../../string_helpers'
import { findBeginningOfCurrentLine, updateTextareaState } from './textarea_utils'
import { tForFile } from 'shared/i18n'

const t = tForFile('web.shared.components.textile_textarea.formatter')

export function bold(textarea) {
  let res = insertAt(textarea.value, '*', textarea.selectionEnd)
  res = insertAt(res.text, '*', textarea.selectionStart)
  return updateTextareaState(textarea, res.text, textarea.selectionEnd + 1)
}

export function italic(textarea) {
  let res = insertAt(textarea.value, '_', textarea.selectionEnd)
  res = insertAt(res.text, '_', textarea.selectionStart)
  return updateTextareaState(textarea, res.text, textarea.selectionEnd + 1)
}

export function underline(textarea) {
  let res = insertAt(textarea.value, '+', textarea.selectionEnd)
  res = insertAt(res.text, '+', textarea.selectionStart)
  return updateTextareaState(textarea, res.text, textarea.selectionEnd + 1)
}

export function heading(textarea, level) {
  if (level < 1 || level > 6) throw new Error(`Heading level ${level} not supported.`)

  const beginningOfLine = findBeginningOfCurrentLine(textarea)
  let res = insertAt(textarea.value, `\nh${level}. `, beginningOfLine)
  res = insertAt(res.text, '\n', findEndOfLine(res.text, res.position))
  return updateTextareaState(textarea, res.text, textarea.selectionEnd + 5)
}

function list(textarea, token) {
  let beginningOfLine = findBeginningOfCurrentLine(textarea)
  let res = insertAt(textarea.value, `\n${token} `, beginningOfLine)
  const start = textarea.selectionStart + 3
  let end = textarea.selectionEnd + 3
  beginningOfLine = res.text.indexOf('\n', res.position) + 1
  while (beginningOfLine !== 0 && beginningOfLine <= end) {
    res = insertAt(res.text, `${token} `, beginningOfLine)
    end += 2
    beginningOfLine = res.text.indexOf('\n', beginningOfLine + 2) + 1
  }
  res = insertAt(res.text, '\n', findEndOfLine(res.text, end))
  return updateTextareaState(textarea, res.text, start, end)
}

export function orderedList(textarea) {
  return list(textarea, '#')
}

export function unorderedList(textarea) {
  return list(textarea, '*')
}

export function link(textarea) {
  let linkText = 'link text'
  if (textarea.selectionStart !== textarea.selectionEnd) {
    linkText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd)
  }
  const exampleLink = 'http://example.com/'
  const res = replaceAt(textarea.value, `"${linkText}":${exampleLink}`, textarea.selectionStart, textarea.selectionEnd)
  return updateTextareaState(
    textarea,
    res.text,
    textarea.selectionStart + 1,
    textarea.selectionStart + 1 + linkText.length,
  )
}

export function image(textarea) {
  const res = insertAt(
    textarea.value,
    '!https://media.giphy.com/media/2IodXMfbcVVrW/giphy.gif!',
    textarea.selectionStart,
  )
  return updateTextareaState(textarea, res.text, textarea.selectionStart + 1, res.position - 1)
}

export function youtube(textarea) {
  const res = insertAt(textarea.value, 'youtube. https://www.youtube.com/watch?v=GcgN0lEh5IA', textarea.selectionStart)
  return updateTextareaState(textarea, res.text, textarea.selectionStart + 'youtube. '.length, res.position)
}

export function blockQuote(textarea) {
  const beginningOfLine = findBeginningOfCurrentLine(textarea)
  const insertion = `\n%(blockquote)${t('.quote_demo')}%`
  let res = insertAt(textarea.value, insertion, beginningOfLine)
  res = insertAt(res.text, '\n', findEndOfLine(res.text, res.position))
  return updateTextareaState(textarea, res.text, textarea.selectionStart + insertion.length)
}

export function table(textarea) {
  const res = insertAt(
    textarea.value,
    '\n\ntable(table table-bordered).\n|_.Name  |_.Age|\n|Walter  |5    |\n|Florence|6    |\n\n',
    textarea.selectionStart,
  )
  return updateTextareaState(textarea, res.text, textarea.selectionStart + 2)
}

export function insert(textarea, textToInsert) {
  const res = insertAt(textarea.value, textToInsert, textarea.selectionStart)
  return updateTextareaState(textarea, res.text, res.position)
}
