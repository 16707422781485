import './selectable_table.scss'

export default function selectableTable($table) {
  const $actions = $('.contextual-actions')
  const $selectAllBtn = $actions.find('.btn.select-all')
  const $selectAll = $selectAllBtn.find('input')

  const $tbody = $table.find('tbody')

  const updateState = () => {
    $selectAll.prop('checked', $tbody.find('tr:not(.selected)').length === 0)
    const isRowsSelected = $tbody.find('tr.selected').length > 0
    return $actions.toggleClass('some-selected', isRowsSelected)
  }

  const markAllSelected = (selected) => {
    $selectAll.prop('checked', selected)
    $tbody.find('tr').toggleClass('selected', selected)
      .find('.select').prop('checked', selected)
    return updateState()
  }

  // when clicking on select, prevent percolating to btn handler and mark all
  $selectAll.click((e) => {
    e.stopPropagation()
    markAllSelected($selectAll.is(':checked'))
  })

  // when btn catches the click, invert the current input checked status
  $selectAllBtn.click(() => {
    markAllSelected(!$selectAll.is(':checked'))
  })

  $tbody.on('click', '.select, img', function selectableTableOnBodyClick() {
    const tr = $(this).parents('tr')
    tr.toggleClass('selected')
    tr.find('.select').prop('checked', tr.hasClass('selected'))
    return updateState()
  })

  // mark rows checked if we came back to this page w/ the back button
  $tbody.find('tr .select:checked').each(function markRowsCheckedOnBackNavigation() {
    return $(this).parents('tr').addClass('selected')
  })

  return updateState()
}
