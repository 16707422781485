/**
 * @deprecated Use v2 in favor of this one, as the new version is easier to test.
 * Prefixes a path with the school ID prefix, /s/{schoolId}/.
 * @param {string} path The path to be prefixed, without the leading slash. e.g. 'classrooms/123/children'
 * @return {string}
 */
export const schoolUrlHelper = (path) => {
  // Regex: matches /s/{number} from the beginning of the path string
  const schoolId = window.location.pathname.match(/^\/s\/(\d+)\//)[1]
  return `/s/${schoolId}/${path}`
}


export const schoolUrlHelperV2 = path => `/s/${window.tc.env.currentSchoolId}/${path}`
