import React from 'react'
import { tForFile } from 'shared/i18n'
import { Loading } from 'modules/bootstrap_components/components/loading'
import './spinner_overlay.scss'
import { optProp } from 'shared/object_helpers'
import useDelayedRender from 'use-delayed-render'

const t = tForFile('web.shared.components.spinner_overlay')

export const SpinnerOverlay = ({ blank, state, showing, enterDelay = 200 }) => {
  // reduces likelihood of the "flicker" effect when the spinner shows up only briefly when network requests are fast
  // https://github.com/pacocoursey/use-delayed-render
  const { rendered } = useDelayedRender(showing, { enterDelay })
  const text = state === 'saving' ? t('actions.saving') : t('actions.loading')

  if (!rendered) return null

  return (
    <div className="SpinnerOverlay">
      <Loading {...optProp(!blank, { text })} />
    </div>
  )
}
