import { path } from 'shared/ramda_loader'
import { tForFile } from './shared/i18n'
import { crappyWayToShowAlerts } from './shared/error_handling'
import spinner64 from '../web/images/spinner-64.gif'

const t = tForFile('web.choose_profile_photo')

/* eslint-disable max-len */

function saveProfilePhoto(saveUrl, photoUrl, photoSelector, fileToUpload, onSuccess, onError) {
  if (photoUrl) {
    $(photoSelector).attr('src', spinner64)
    $.ajax({
      url: saveUrl,
      type: 'POST',
      data: { photo: { url: photoUrl } },
      success: onSuccess,
      error: onError,
    })
  } else if (fileToUpload) {
    const data = new FormData()
    data.append('photo[file]', fileToUpload)
    $(photoSelector).attr('src', spinner64)
    $.ajax({
      url: saveUrl,
      type: 'POST',
      data,
      cache: false,
      dataType: 'json',
      processData: false,
      contentType: false,
      success: onSuccess,
      error: onError,
    })
  }
}

/**
 * show a photo chooser
 * @param options.photosUrl url to hit to load paged photos
 * @param options.mode (upload|pick) if upload, then it will display upload form, and save image, otherwise,
 *                                   just call callback
 * @param options.saveUrl url to upload chosen image to
 * @param options.callback method to call on save
 * @param options.photoSelector jQuery selector text to target the image that will be updated (necessary to show
 *                                loading spinner)
 * @returns {*}
 */
export default function chooseProfilePhoto(options) {
  const { photosUrl, saveUrl, photoSelector, callback, mode } = options

  $('#profile-photos').remove()
  const $modal = $(`
<div id='profile-photos' class='modal fade'>
  <div class='modal-dialog modal-lg'>
    <div class='modal-content'>
      <div class='modal-header'>
        <h5 class='modal-title'>${t('.title')}</h3>
        <button type='button' class='close' data-dismiss='modal' aria-label='close'>&times;</button>
      </div>
      <div class='modal-body'>
        <p>${t(`.${mode}_hint`)}</p>
        ${mode === 'upload' ? (
    `<form class='card card-body bg-light mb-3'>
            <input type='file' accept='image/jpg,image/jpeg,image/png' class="form-control-file"/>
          </form>`
  ) : ''}
        <div class='form-row thumbnails'></div>
      </div>
      <div class='modal-footer modal-footer__split'>
        <div>
          <button data-dismiss='modal' aria-hidden='true' class='btn btn-secondary'>${t('actions.cancel')}</button>
          <button class='upload-btn btn btn-primary'>${t(`.${mode}_btn`)}</button>
        </div>
        <button class='btn btn-secondary load-more' page='1'>${t('.load_more_btn')}</button>
      </div>
    </div>
  </div>
</div>`)

  // If a photo is selected, the URL is stored here. It's updated through a bunch of sneaky listeners.
  let photoUrl = null

  const uploadAndClose = () => {
    // Set up success/fail handlers...
    const oldUrl = $(photoSelector).attr('src')
    const onDone = (url) => {
      $(photoSelector).attr('src', url)
      if (callback) callback(url)
    }
    const onError = (xhr) => {
      crappyWayToShowAlerts(xhr)
      $(photoSelector).attr('src', oldUrl)
    }

    // Perform the save action depending on the mode
    const fileToUpload = path(['0', 'files', '0'])($('input[type="file"]', $modal))
    if (fileToUpload || photoUrl) {
      if (mode === 'upload') {
        saveProfilePhoto(
          saveUrl, photoUrl, photoSelector, $('input[type="file"]', $modal)[0].files[0], x => onDone(x.url),
          onError,
        )
      } else {
        onDone(photoUrl)
      }
    }

    $modal.modal('hide')
  }

  $('.upload-btn', $modal).click(uploadAndClose)
  $('input[type="file"]', $modal).change(() => {
    photoUrl = null
    return $('.selected', $modal).removeClass('selected')
  })

  $('form', $modal).submit(() => {
    uploadAndClose()
    return false
  })

  const loadPhotos = page => (
    $.getJSON(photosUrl, { page }, (photos) => {
      $('.load-more', $modal).attr('page', page)
      return photos.forEach(photo => (
        $('.thumbnails', $modal).append(
          `<div class='col-md-3'><div class='card p-2 mb-2'><a href='#' class='thumbnail' url='${photo.large_photo_url}'><img src='${photo.photo_url}' class='card-img-top'/></a></div></div>`)
      ))
    })
  )

  $($modal).on('click', '.load-more', function loadMore() {
    return loadPhotos(parseInt($(this).attr('page')) + 1)
  })

  $($modal).on('click', '.thumbnails .card', function selectPhoto() {
    $('.selected', $modal).removeClass('selected')
    $(this).addClass('selected')
    photoUrl = $(this).find('a').attr('url')
    return false
  })

  loadPhotos(1)

  $('body').append($modal)
  return $modal.modal('show')
}
