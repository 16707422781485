import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['flash', 'submit', 'input'];

  connect() {
    this.listeners = {};
    this.errors = {};

    this.submitTarget.disabled = true;

    Array.from(this.element).forEach((elem) => {
      this.listeners[elem.name] = [];
      this.listeners[elem.name].push(
        elem.addEventListener('change', (e) => {
          if (e.target.validity.valid) {
            this.unsetError(elem.name, e.target);
          }
        }),
      );
      this.listeners[elem.name].push(
        elem.addEventListener('invalid', (e) => {
          this.setError(elem.name, e.target);
        }),
      );
    });

    // allows other stuff to call methods on this controller
    // e.g. allows a file upload component to "undisable" for re-upload
    this.element[this.identifier] = this;
  }

  disconnect() {
    Object.keys(this.listeners).forEach((name) => {
      this.listeners[name].forEach((listener) => {
        this.element.elements[name].removeEventListener(listener);
      });
    });
  }

  setError(name, element) {
    this.errors[name] = element.validationMessage;
    this.setFlash();
  }

  unsetError(name, element) {
    this.errors[name] = '';
    this.setFlash();
    if (this.nonEmptyErrors.length === 0) {
      this.undisable();
    }
  }

  setFlash() {
    this.flashTarget.innerHTML = this.nonEmptyErrors.join(' ');
  }

  get nonEmptyErrors() {
    return Object.values(this.errors).filter(e => e && e.length > 0);
  }

  undisable() {
    this.submitTarget.disabled = false;
    this.submitTarget.value = this.submitTarget.dataset.undisableWith;
  }

  fileInputChange() {
    const filePickerStatus = this.inputTarget.dataset.state
    // eslint-disable-next-line no-prototype-builtins
    this.submitTarget.disabled = !filePickerStatus.hasOwnProperty('has-photo');
  }
}
